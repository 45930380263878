import { Product } from './product'
import { GeoLocation } from './geoLocation'
import { CO2ByYear, KeyValuePair, VariableBaseNode } from '../types'
import VariableService from './service'
import { ActivityItem } from './activity'
import { UIOptionActionType } from './ui'

export interface Taxonomy extends VariableBaseNode {
    name: string
    slug: string
    path?: string
    description?: string
    productCount?: number
    products?: Product[]
    parent?: Taxonomy
    children?: Taxonomy[]
    co2e?: string
    co2eByYear?: CO2ByYear[]
    marketTotalCo2e?: number
    marketCo2eByYear?: CO2ByYear[]
    totalCo2e?: number
    activityCount?: number
    filterCount?: number
}

export interface TaxonomyGeoLocations {
    geoLocation: GeoLocation
    products: Product[]
}

export default class TaxonomyService extends VariableService {
    private basePath: string = '/taxonomy'

    public static all: Taxonomy[] = []
    public static topLevel: Taxonomy[] = []
    public static optionList: KeyValuePair<string>[] = []
    public static byId: Map<string, Taxonomy> = new Map<string, Taxonomy>()
    public static byPath: Map<string, Taxonomy> = new Map<string, Taxonomy>()

    public static electricityPath = 'energy/electricity'
    public static energyPath = 'energy'

    public setTaxonomyData(taxonomyData: Taxonomy[]): void {
        const payloadData = TaxonomyService.setTaxonomyDetail(taxonomyData)
        const all = TaxonomyService.getFlatList(payloadData)
        const byId = new Map<string, Taxonomy>()
        const byPath = new Map<string, Taxonomy>()
        const optionList: KeyValuePair<string>[] = []
        const topLevel: Taxonomy[] = []
        all.forEach((t) => {
            byId.set(t.uuid!, t)
            byPath.set(t.path!, t)
            if (!t.parent) {
                topLevel.push(t)
            }
        })
        all.forEach((t) => {
            optionList.push({
                name: TaxonomyService.getTaxonomyString(t, ' > ', undefined, byId),
                value: t.uuid as string,
                node: t,
            })
        })
        TaxonomyService.all = all
        TaxonomyService.topLevel = topLevel
        TaxonomyService.optionList = optionList
        TaxonomyService.byId = byId
        TaxonomyService.byPath = byPath
        this.context.dispatch({ type: UIOptionActionType.TaxonomyReady, payload: true })
    }

    public static basicTaxonomy(taxonomy?: Taxonomy): Taxonomy | undefined {
        if (!taxonomy) return undefined
        return {
            uuid: taxonomy.uuid,
            name: taxonomy.name,
            slug: taxonomy.slug,
            path: taxonomy.path,
        }
    }

    public static getPlainTaxonomy(taxonomy?: Taxonomy): Taxonomy | undefined {
        if (!taxonomy) return undefined
        const { parent, children, ...plainTx } = taxonomy
        return plainTx
    }

    public static getFlatList(taxonomies: Taxonomy[]): Taxonomy[] {
        const _list: Taxonomy[] = []
        taxonomies.forEach((t) => {
            _list.push(t)
            _list.push(...TaxonomyService.getFlatList(t.children || []))
        })
        return _list
    }

    public getTaxonomyString(
        taxonomy?: Taxonomy | null,
        joinWith: string = ' > ',
        excludePaths: (string | undefined)[] = [],
    ): string {
        return TaxonomyService.getTaxonomyString(taxonomy, joinWith, excludePaths, TaxonomyService.byId)
    }

    public static getTaxonomyString(
        taxonomy: Taxonomy | undefined | null,
        joinWith: string = ' > ',
        excludePaths: (string | undefined)[] = [],
        allTaxonomies: Map<string, Taxonomy>,
    ): string {
        if (!taxonomy) return ''
        const _txs = TaxonomyService.getTaxonomyArray(taxonomy, allTaxonomies).filter(
            (t) => !excludePaths.includes(t.path || ''),
        )
        return _txs.map((t) => t.name).join(joinWith)
    }

    public getTaxonomyArray(taxonomy?: Taxonomy): Taxonomy[] {
        return TaxonomyService.getTaxonomyArray(taxonomy, TaxonomyService.byId)
    }

    public static getTaxonomyArray(
        taxonomy: Taxonomy | undefined | null,
        allTaxonomies: Map<string, Taxonomy>,
    ): Taxonomy[] {
        let _taxonomy = allTaxonomies.get(taxonomy?.uuid || '')
        if (!_taxonomy) {
            return []
        }
        const _taxonomyArray: Taxonomy[] = []
        let i = 0
        while (_taxonomy?.parent && i < 100) {
            _taxonomyArray.unshift(_taxonomy)
            _taxonomy = allTaxonomies.get(_taxonomy.parent.uuid || '')
            i++
        }
        if (_taxonomy) {
            _taxonomyArray.unshift(_taxonomy)
        }
        return _taxonomyArray
    }

    public static setTaxonomyDetail(taxonomies: Taxonomy[], parent?: Taxonomy): Taxonomy[] {
        taxonomies.forEach((t) => {
            t.parent = parent
            t.children = TaxonomyService.setTaxonomyDetail(t.children || [], t)
        })
        return taxonomies
    }

    public isInTaxonomyTree(taxonomy: Taxonomy, currentTaxonomy?: Taxonomy | null): boolean {
        return TaxonomyService.isInTaxonomyTree(taxonomy, currentTaxonomy, TaxonomyService.byId)
    }

    public static isInTaxonomyTree(
        taxonomy: Taxonomy | undefined,
        currentTaxonomy: Taxonomy | undefined | null,
        allTaxonomies: Map<string, Taxonomy>,
    ): boolean {
        const _currentPath = allTaxonomies.get(currentTaxonomy?.uuid || '')?.path
        if (!_currentPath) {
            return false
        }
        const _taxonomyPath = allTaxonomies.get(taxonomy?.uuid || '')?.path
        if (!_taxonomyPath) {
            return false
        }
        return _currentPath.startsWith(_taxonomyPath)
    }

    public static canBeDeleted(taxonomy?: Taxonomy): boolean {
        return !!(taxonomy?.uuid && !taxonomy.children?.length && !taxonomy.productCount && !taxonomy.products?.length)
    }

    public async get(queryString?: string, cacheOk: boolean = true): Promise<Taxonomy[]> {
        if (cacheOk && TaxonomyService.all.length && !queryString) {
            return TaxonomyService.all
        }
        return await this.httpService.get<Taxonomy[]>(`${this.basePath}?${queryString || ''}`).then((tx) => {
            if (!queryString) {
                this.setTaxonomyData(tx)
            }
            return tx
        })
    }

    public async getActivities(queryString?: string): Promise<ActivityItem[]> {
        const qs = new URLSearchParams(queryString)
        qs.set('admin', 'true')
        let url = this.basePath
        if (qs.get('taxonomy')) {
            url = `${this.basePath}/${qs.get('taxonomy')}`
        }
        return await this.httpService.get<ActivityItem[]>(`${url}/activities?${qs.toString()}`)
    }

    public async create(taxonomy: Taxonomy): Promise<Taxonomy> {
        return await this.httpService.post<Taxonomy>(this.basePath, {
            body: JSON.stringify({ taxonomy }),
        })
    }

    public async moveTaxonomy(taxonomy: Taxonomy, moveTo?: Taxonomy): Promise<Taxonomy[]> {
        return await this.httpService.patch<Taxonomy[]>(`${this.basePath}/${taxonomy.uuid}`, {
            body: JSON.stringify({ moveToParentId: moveTo?.uuid }),
        })
    }

    public async moveActivities(fromTaxonomy?: Taxonomy, toTaxonomy?: Taxonomy): Promise<void> {
        return await this.httpService.post<void>(this.basePath, {
            body: JSON.stringify({
                move: 'activities',
                fromTaxonomyId: fromTaxonomy?.uuid,
                toTaxonomyId: toTaxonomy?.uuid,
            }),
        })
    }

    public async moveProducts(productIds?: string[], moveTo?: Taxonomy): Promise<void> {
        return await this.httpService.post<void>(this.basePath, {
            body: JSON.stringify({ productIds, moveTo: moveTo?.uuid }),
        })
    }

    public async delete(taxonomy: Taxonomy): Promise<Taxonomy[]> {
        return await this.httpService.delete<Taxonomy[]>(`${this.basePath}/${taxonomy.uuid}`)
    }

    public async setPaths(): Promise<Taxonomy[]> {
        return await this.httpService.post<Taxonomy[]>(this.basePath, { body: JSON.stringify({ paths: true }) })
    }
}
