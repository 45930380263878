import { lazy, ReactNode, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import AuthenticationService from './services/authentication'
import { BrowserRouter, Link, Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { Navigation } from './components/Navigation'
import CompanyService from './services/company'
import ActivityService from './services/activity'
import ProductService from './services/product'
import TokenService from './services/token'
import { Action, createBrowserHistory } from 'history'
import PartService from './services/part'
import { Slide, toast, ToastContainer } from 'react-toastify'
import HttpService from './services/http'
import PlanService from './services/plan'
import CalculatorService from './services/calculator'
import UserService from './services/user'
import DataRequestService, { DataRequestType } from './services/dataRequest'
import OrgService from './services/org'
import FileService from './services/file'
import { VariableAuthProvider } from './components/Auth0'
import { Forward } from './pages/Forward'
import { SsoPage } from './pages/Sso'
import { DataRequestProvider } from './services/dataRequestContext'
import { LoadingPage } from './components/LoadingPage'
import { UpgradeModal } from './components/Subscription/UpgradeModal'
import { CommandPalette } from './components/CommandPalette'
import { ActivityProvider } from './services/activityContext'
import { CommandLauncher } from './components/CommandLauncher'
import { AccountHeader } from './components/AccountHeader'
import Logo from './components/Logo'
import { Img } from './components/Img'
import Tooltip from './components/Tooltip'
import Utils from './services/utils'
import { useKeyboard } from './hooks/useKeyboard'
import { HistoryCrumb } from './components/Breadcrumb/HistoryCrumb'
import { Archive, IconContext, Lightning, List, Plus } from '@phosphor-icons/react'
import { HealthCheck } from './types'
import { CreateAccountModal } from './components/Company/CreateAccountModal'
import uiPackage from '../package.json'
import { ReactFlowProvider } from 'react-flow-renderer'
import BatchService from './services/batch'
import { ProductDetailModal } from './components/Product/ProductDetailModal'
import { VariableServicesContext } from './services'
import { ApplicationContext } from './context'
import UiService, { UIOptionActionType } from './services/ui'
import DataImportService from './services/dataImport'
import { FilterManager } from './components/Input/FilterManager'
import Card from './components/Card'
import Spinner from './components/Spinner'
import TransportService from './services/transport'
import { InventoryService, InventoryType } from './services/inventory'
import { ElementCreator } from './components/ElementCreator'
import UpdateAvailableModal from './components/UpdateAvailableModal'
import Button from './components/Input/Button'
import { InventoryIcon } from './components/Icons/InventoryIcon'
import { TransportIcons } from './components/Icons/TransportIcons'
import EnergyService from './services/energy'
import TaxonomyService from './services/taxonomy'
import LocationService from './services/location'
import { InputEditor } from './components/Product/InputEditor'
import { PartEditor } from './components/Part/PartEditor'
import { TransportEditor } from './components/Transport/TransportEditor'
import { ProcessingEditor } from './components/Input/ProcessingEditor'
import { UseStageTypeEditor } from './components/Input/UseStageTypeEditor'
import ElectricityService from './services/electricity'
import { EnergyIcon } from './components/Icons/EnergyIcon'
import { ElectricityCreator } from './components/Electricity/ElectricityCreator'
import { PartCreator } from './components/Part/PartCreator'
import SyncService from './services/sync'
import ExchangeService from './services/exchange'
import FlagService, { FlagType } from './services/flag'
import { useSupportWidget } from './hooks/useSupportWidget'

const AuthPage = lazy(() => import('./pages/AuthPage'))
const AdminPage = lazy(() => import('./pages/Admin'))
const ActivityPage = lazy(() => import('./pages/Activity'))
const AnalyticsPage = lazy(() => import('./pages/Analytics'))
const BatchPage = lazy(() => import('./pages/Batch'))
const CalculatorPage = lazy(() => import('./pages/Calculator'))
const CompanyPage = lazy(() => import('./pages/Company'))
const CustomerPage = lazy(() => import('./pages/Customer'))
const DatabasePage = lazy(() => import('./pages/Database'))
const DataRequestPage = lazy(() => import('./pages/DataRequest'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const DataImports = lazy(() => import('./pages/DataImports'))
const DataImportDetail = lazy(() => import('./components/DataImport/DataImportDetail'))
const DeleteModal = lazy(() => import('./components/DeleteModal'))
const DocumentPage = lazy(() => import('./pages/Document'))
const ErrorPage = lazy(() => import('./pages/Error'))
const ExchangePage = lazy(() => import('./pages/ExchangePage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const InventoryPage = lazy(() => import('./pages/InventoryPage'))
const JoinPage = lazy(() => import('./pages/Join'))
const LocationModal = lazy(() => import('./components/Location/LocationModal'))
const LocationsPage = lazy(() => import('./pages/LocationsPage'))
const ModelAnonymousUi = lazy(() => import('./components/CategoryModel/ModelAnonymousUi'))
const TokenPage = lazy(() => import('./pages/Token'))
const NotFoundPage = lazy(() => import('./pages/404'))
const OrgPage = lazy(() => import('./pages/OrgPage'))
const PartPage = lazy(() => import('./pages/Part'))
const PartConnectModal = lazy(() => import('./components/Part/PartConnectModal'))
const PassportPage = lazy(() => import('./pages/PassportPage'))
const PlanPage = lazy(() => import('./pages/PlanPage'))
const PlanDetail = lazy(() => import('./components/Plan/PlanDetail'))
const PricingPage = lazy(() => import('./pages/PricingPage'))
const ProductPage = lazy(() => import('./pages/Product'))
const ProfilePage = lazy(() => import('./pages/Profile'))
const PublicDashboard = lazy(() => import('./pages/PublicDashboard'))
const ReportPage = lazy(() => import('./pages/Report'))
const RequestFootprintForm = lazy(() => import('./components/RequestFootprintForm'))
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'))
const SignOutPage = lazy(() => import('./pages/SignOut'))
const SupplierPage = lazy(() => import('./pages/Supplier'))
const TransportElement = lazy(() => import('./components/Transport/TransportElement'))
const TransportManager = lazy(() => import('./components/Transport/TransportManager'))
const VersionPage = lazy(() => import('./pages/Version'))

const history = createBrowserHistory()
AuthenticationService.setupDocumentListeners()

const AuthCheck = () => {
    if (!AuthenticationService.isOnboarded()) {
        AuthenticationService.sendUserToAuth()
        return null
    }
    return <Outlet />
}

const ScrollToTop = () => {
    const { pathname } = useLocation()
    const [params] = useSearchParams()
    useKeyboard()

    useEffect(() => {
        if (window && window.scrollTo) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
        const variableContentCx = document.getElementById('variable-content-cx')
        if (variableContentCx && variableContentCx.scrollTo) {
            variableContentCx.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
        const listContainer = document.querySelectorAll('.list-container')
        listContainer.forEach((el) => {
            if (el.scrollTo) el.scrollTo({ top: 0, left: 0, behavior: 'auto' })
        })
    }, [pathname, params])

    return null
}

const App = () => {
    const context = useContext(ApplicationContext)
    const {
        authenticationService,
        userService,
        unitService,
        uiService,
        taxonomyService,
        ghgService,
        orgService,
        companyService,
        partService,
        productService,
        transportService,
        inputService,
        statusService,
        geoLocationService,
        locationService,
        exchangeService,
    } = useContext(VariableServicesContext)
    const [isReady, setIsReady] = useState<boolean>(false)
    const [maintenance, setMaintenance] = useState<string>()
    const [colorModeReady, setColorModeReady] = useState<boolean>(false)
    useSupportWidget()

    useEffect(() => init(), [context.stores.company?.uuid])

    useEffect(() => {
        if (colorModeReady) {
            return
        }
        setColorModeReady(true)
        let darkModePreference: MediaQueryList
        try {
            darkModePreference = window.matchMedia('(prefers-color-scheme: dark)')
            darkModePreference.addEventListener('change', () => userService.setColorMode())
            userService.setColorMode()
        } catch (e) {
            console.error(e)
        }
        return () => {
            darkModePreference?.removeEventListener('change', () => userService.setColorMode())
        }
    }, [])

    useEffect(() => {
        console.debug('React Env:', process.env.REACT_APP_VARIABLE_ENV)
        if (uiPackage.version) context.dispatch({ type: UIOptionActionType.SetUiVersion, payload: uiPackage.version })
        history.listen((loc) => {
            if (loc.action === Action.Pop) uiService.backButton()
        })
        HttpService.fetch<HealthCheck>('get', '/health')
            .then(() => (HttpService.apiOnline = true))
            .then(async () => {
                await unitService.getUnits()
                await authenticationService.initUser()
                setIsReady(true)
            })
            .catch(async (e) => {
                if (e?.message?.includes('scheduled maintenance')) {
                    setMaintenance(e.message)
                    setTimeout(() => window.location.reload(), 1000 * 60 * 5)
                    return
                }
                HttpService.apiOnline = false
                toast.warning(
                    <div>
                        <h6 className='fw-bold'>We are having some connection issues.</h6>This should be very temporary.
                        You can try refreshing the page to try again.
                    </div>,
                    {
                        autoClose: false,
                    },
                )
                setTimeout(() => {
                    HttpService.apiOnline = true
                }, 500)
                await authenticationService.initUser()
                setIsReady(true)
            })
    }, [])

    useEffect(() => {
        if (isReady) {
            window.addEventListener('focus', onWindowFocus)
            window.onunload = () => window.removeEventListener('focus', onWindowFocus)
            return () => {
                window.removeEventListener('focus', onWindowFocus)
            }
        }
    }, [isReady])

    const onWindowFocus = useCallback(() => {
        authenticationService.checkAuthOnWindowFocus()
        statusService.backgroundStatusCheck()
        context.dispatch({
            type: UIOptionActionType.SetModifierKeys,
            payload: { altKey: false, ctrlKey: false, shiftKey: false, metaKey: false },
        })
    }, [])

    const init = useCallback(() => {
        if (!HttpService.apiOnline) return
        // console.log('init')
        taxonomyService.get().then()
        ghgService.getScopes().then()
        if (!context.stores.company?.uuid) return
        statusService.getStatus()
        orgService.getSlim().then()
        companyService.getSlimCompanies()
        companyService.getMembers().then()
        companyService.getRoles().then()
        partService.getSlimParts()
        productService.getSlimProducts()
        transportService.getTransportTypes().then()
        inputService.getUseStages()
        geoLocationService.getMyLocations().then()
        locationService.getLocations().then()
        uiService.getUiSettings()
        exchangeService.get()
    }, [context.stores.company?.uuid, context.stores.user?.type])

    const subdomain = useMemo(() => HttpService.getSubdomain(), [])

    const content = useMemo(() => {
        if (!isReady) {
            return <LoadingPage />
        }

        const hn = document.location?.hostname

        if (['climate.jimmybyrum.com', 'klima.jimmybyrum.com'].includes(hn)) {
            return (
                <Suspense fallback={<Spinner />}>
                    <Dashboard />
                </Suspense>
            )
        }

        if (subdomain) {
            console.debug('Subdomain', subdomain)
            return (
                <VariableRoutes
                    homePage={
                        <Suspense fallback={<Spinner />}>
                            <PublicDashboard />
                        </Suspense>
                    }
                />
            )
        }

        if (HttpService.isDatabaseSubdomain(hn)) {
            return (
                <VariableRoutes
                    homePage={
                        <Suspense fallback={<Spinner />}>
                            <DatabasePage />
                        </Suspense>
                    }
                />
            )
        }

        return <VariableRoutes />
    }, [
        isReady,
        subdomain,
        context.stores.ui?.showLoggedOutSidebar,
        context.stores.user?.uuid,
        context.stores.company?.uuid,
    ])

    if (maintenance) {
        return (
            <div className='container-sm dvh-100'>
                <div className='d-flex align-items-start justify-content-center h-100 mt-5'>
                    <Card
                        innerClassName='d-flex flex-column align-items-center justify-content-center'
                        style={{ width: '600px', maxWidth: '100%' }}
                    >
                        <Logo
                            className='mb-3'
                            logoStyle='header'
                            version={Utils.colorMode === 'dark' ? 'logo-horizontal-ondark' : 'logo-horizontal-onlight'}
                            style={{ width: '120px', height: '25px' }}
                        />
                        {maintenance}
                    </Card>
                </div>
            </div>
        )
    }

    return (
        <div id='app-container'>
            <IconContext.Provider value={{ size: Utils.smallIconSize, weight: 'bold', className: 'nt--1' }}>
                <ReactFlowProvider>
                    <BrowserRouter>
                        <ToastContainer transition={Slide} hideProgressBar={true} />
                        <ScrollToTop />
                        <VariableAuthProvider>
                            <AppStructure>{content}</AppStructure>
                            <Suspense>
                                <LocationModal />
                            </Suspense>
                            <Suspense>
                                <TransportElement />
                            </Suspense>
                            <Suspense>
                                <PartConnectModal />
                            </Suspense>
                            <ElectricityCreator />
                            <UpgradeModal />
                            <FilterManager />
                            <ProductDetailModal />
                            <InputEditor />
                            <PartEditor />
                            <Suspense>
                                <PlanDetail />
                            </Suspense>
                            <TransportEditor />
                            <ProcessingEditor />
                            <UseStageTypeEditor />
                            <ElementCreator />
                            <PartCreator />
                            <CommandPalette />
                            <Suspense>
                                <RequestFootprintForm />
                            </Suspense>
                            <Suspense>
                                <DeleteModal />
                            </Suspense>
                            <UpdateAvailableModal />
                            <CreateAccountModal />
                        </VariableAuthProvider>
                    </BrowserRouter>
                </ReactFlowProvider>
            </IconContext.Provider>
        </div>
    )
}

export const AppStructure = (props: { children: ReactNode }) => {
    const context = useContext(ApplicationContext)
    const location = useLocation()

    const envBorder = useMemo(() => {
        switch (process.env.REACT_APP_VARIABLE_ENV) {
            case 'local':
                return 'border-top border-secondary border-5'
            case 'dev':
                return 'border-top border-info border-5'
            default:
                return ''
        }
    }, [])

    if (location.pathname.startsWith(ProductService.webRootEmbed)) {
        return <>{props.children}</>
    }

    return (
        <div className={['d-flex flex-column dvh-100 max-w-100 overflow-md-hidden', envBorder].join(' ')}>
            <div className='d-flex flex-grow-1 h-100 max-w-100'>
                {(AuthenticationService.isOnboarded() || context.stores.ui?.showLoggedOutSidebar) && (
                    <Navigation id='variable-nav' className='d-none d-lg-flex' />
                )}
                <div id='variable-content' className='d-flex flex-column flex-grow-1 h-100 max-w-100'>
                    {AuthenticationService.isOnboarded() && (
                        <div className='d-none d-lg-flex align-items-center justify-content-end border-bottom py-2 px-3'>
                            <HistoryCrumb />
                            <CommandLauncher className='flex-grow-1' />
                            <AccountHeader style={{ width: '120px' }} />
                        </div>
                    )}
                    <MobileHeader />
                    <div id='variable-content-cx' className='d-flex flex-column p-3 px-lg-4 flex-grow-1 overflow-auto'>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const MobileHeader = () => {
    const context = useContext(ApplicationContext)
    if (context.stores.ui?.showMobileHeader === false) return null
    return (
        <div className='d-flex w-100 d-lg-none align-items-center justify-content-between border-bottom py-2 px-3'>
            <Tooltip
                interactive={true}
                placement='bottom'
                showArrow={false}
                tooltipClassName='w-100 border-0 p-0'
                tooltipContent={<Navigation showLogo={false} extraClassName='dvh-90' />}
                closeOnInteraction={true}
                className='d-flex align-items-center gap-2'
            >
                <Logo className='my-2' logoStyle='header' style={{ width: '120px', height: '25px' }} />
                <List color={context.stores.ui?.colorMode === 'dark' ? Utils.gray0 : Utils.primaryBackgroundColor} />
            </Tooltip>
            <div className='d-flex align-items-center gap-2'>
                <Link to={UserService.webRootProfile}>
                    <Img
                        src={context.stores.user?.profileImageUrl}
                        alt={context.stores.user?.firstName}
                        size='40px'
                        initials={true}
                        className='rounded-circle ms-2'
                    />
                </Link>
                <Link to={CompanyService.webRootAdmin}>
                    <Img
                        src={context.stores.company?.logoUrl}
                        alt={context.stores.company?.name}
                        placeholder='40px'
                        placeholderClassName='bg-light rounded-2 d-flex align-items-center justify-content-center'
                        initials={true}
                        width='auto'
                        height='30px'
                        className='ms-2'
                    />
                </Link>
            </div>
        </div>
    )
}

export const VariableRoutes = (props: { homePage?: ReactNode }) => {
    const { transportService, electricityService, userService } = useContext(VariableServicesContext)
    return (
        <Routes>
            <Route
                path='/pricing'
                element={
                    <Suspense fallback={<Spinner />}>
                        <PricingPage />
                    </Suspense>
                }
            />
            <Route
                path='/checkout'
                element={
                    <Suspense fallback={<Spinner />}>
                        <PricingPage />
                    </Suspense>
                }
            />

            <Route
                path={`${BatchService.webRoot}/:batchId`}
                element={
                    <ActivityProvider>
                        <Suspense fallback={<Spinner />}>
                            <BatchPage />
                        </Suspense>
                    </ActivityProvider>
                }
            />
            <Route
                path={BatchService.webRootList}
                element={
                    <ActivityProvider>
                        <Suspense fallback={<Spinner />}>
                            <BatchPage />
                        </Suspense>
                    </ActivityProvider>
                }
            />

            <Route
                path={`${AuthenticationService.welcomePath}/:welcomeToken?`}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <AuthPage />
                    </Suspense>
                }
            />
            <Route
                path={`${AuthenticationService.signInPath}/*`}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <AuthPage />
                    </Suspense>
                }
            />
            <Route
                path={`${AuthenticationService.signUpPath}/*`}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <AuthPage />
                    </Suspense>
                }
            />
            <Route
                path={AuthenticationService.resetPasswordPath}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <ResetPasswordPage />
                    </Suspense>
                }
            />
            <Route path='/sso/*' element={<SsoPage />} />
            <Route
                path='/signout'
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <SignOutPage />
                    </Suspense>
                }
            />

            <Route
                path={`${ProductService.webRootDpp}/:id`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <PassportPage />
                    </Suspense>
                }
            />
            <Route
                path={`${ProductService.webRootEmbed}/:id`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <PassportPage />
                    </Suspense>
                }
            />
            <Route
                path={`${ProductService.webRootPassport}/:id`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <PassportPage />
                    </Suspense>
                }
            />
            <Route
                path={`${ProductService.webRootPassport}/:companyId/:id`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <PassportPage />
                    </Suspense>
                }
            />
            <Route
                path={`${FileService.webRoot}/:documentPath`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <DocumentPage />
                    </Suspense>
                }
            />

            <Route
                path={`${ProductService.webRootDatabase}/*`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <DatabasePage />
                    </Suspense>
                }
            />

            <Route
                path={`${TokenService.webRoot}/:token`}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <TokenPage />
                    </Suspense>
                }
            />

            <Route
                path={`${SyncService.rightsAndBrandsWebRoot}/:syncId`}
                element={
                    <Suspense fallback={<Spinner />}>
                        <ModelAnonymousUi />
                    </Suspense>
                }
            />

            <Route
                path={UiService.versionPath}
                element={
                    <Suspense>
                        <VersionPage />
                    </Suspense>
                }
            />

            <Route
                path='/error'
                element={
                    <Suspense fallback={<Spinner />}>
                        <ErrorPage />
                    </Suspense>
                }
            />

            <Route
                path={AuthenticationService.joinPath}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <JoinPage />
                    </Suspense>
                }
            />

            <Route
                path={AuthenticationService.waitlistPath}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <JoinPage view='waitlist' />
                    </Suspense>
                }
            />

            {props.homePage && <Route path='/' element={props.homePage} />}

            <Route element={<AuthCheck />}>
                {!props.homePage && (
                    <Route
                        path='/'
                        element={
                            <ActivityProvider>
                                <DataRequestProvider>
                                    <Suspense>
                                        <HomePage />
                                    </Suspense>
                                </DataRequestProvider>
                            </ActivityProvider>
                        }
                    />
                )}
                <Route
                    path='/admin/:view?'
                    element={
                        <Suspense fallback={<Spinner />}>
                            <AdminPage />
                        </Suspense>
                    }
                />

                <Route path='/map/*' element={<Forward from='/map' to='/model' />} />
                <Route
                    path='/model/footprint/*'
                    element={<Forward from='/model/footprint' to={ProductService.webRoot} />}
                />
                <Route path='/footprints/*' element={<Forward from='/footprints' to={ProductService.webRootList} />} />
                <Route path='/footprint/*' element={<Forward from='/footprint' to={ProductService.webRoot} />} />
                <Route path='/model' element={<Forward from='/model' to={ProductService.webRootList} />} />
                <Route
                    path={ProductService.webRootList}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <ProductPage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${ProductService.webRoot}/:productId/:tab?`}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <ProductPage />
                            </Suspense>
                        </ActivityProvider>
                    }
                />

                <Route
                    path={`${TransportService.webRoot}/:transportTypeId?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <TransportManager />
                        </Suspense>
                    }
                />

                <Route
                    path={`${OrgService.webRoot}/:tab?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <OrgPage />
                        </Suspense>
                    }
                />

                <Route
                    path={LocationService.webRootList}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <LocationsPage />
                        </Suspense>
                    }
                />

                <Route
                    path={`${LocationService.webRootDetail}/:locationId`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <LocationsPage />
                        </Suspense>
                    }
                />

                <Route path='/elements' element={<Forward from='/elements' to={InventoryService.webRoot} />} />
                <Route path='/elements/*' element={<Forward from='/elements' to={InventoryService.webRoot} />} />
                <Route
                    path={InventoryService.webRoot}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <InventoryPage
                                title={InventoryService.webTitle()}
                                icon={
                                    <InventoryIcon size={Utils.mediumIconSize} allInventory={true} className='nt--2' />
                                }
                                newButton={
                                    <Tooltip
                                        interactive={true}
                                        closeOnInteraction={true}
                                        placement='bottom-end'
                                        hidden={userService.isReadonly()}
                                        tooltipContent={
                                            <>
                                                <Link
                                                    to={`${ProductService.webRoot}/${ProductService.newId}?type=element`}
                                                    className='dropdown-item small'
                                                >
                                                    <InventoryIcon /> {InventoryService.webTitleMaterial()}
                                                </Link>
                                                <Button
                                                    className='dropdown-item small d-flex align-items-center gap-1'
                                                    onClick={() => transportService.setTransportType()}
                                                >
                                                    <TransportIcons /> Transport lane
                                                </Button>
                                            </>
                                        }
                                        className='btn btn-sm btn-primary text-nowrap'
                                    >
                                        <Plus /> New {ProductService.elementTitle().toLowerCase()}
                                    </Tooltip>
                                }
                            />
                        </Suspense>
                    }
                />
                <Route
                    path={InventoryService.webRootMaterial}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <InventoryPage
                                title={InventoryService.webTitleMaterial()}
                                queryOptions={{ inventoryTypes: [InventoryType.Part, InventoryType.Product] }}
                                taxonomyExcludePaths={[TaxonomyService.byPath.get('material')?.path]}
                                newButton={
                                    <Link
                                        to={`${ProductService.webRoot}/${ProductService.newId}?type=element`}
                                        hidden={userService.isReadonly()}
                                        className='btn btn-sm btn-primary text-nowrap'
                                    >
                                        <Plus /> New {ProductService.elementTitle().toLowerCase()}
                                    </Link>
                                }
                            />
                        </Suspense>
                    }
                />
                <Route
                    path={EnergyService.webRoot}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <InventoryPage
                                icon={<Lightning size={Utils.mediumIconSize} className='nt--2' />}
                                title={EnergyService.webTitle()}
                                queryOptions={{ taxonomy: TaxonomyService.byPath.get('energy')?.uuid }}
                                taxonomyExcludePaths={[TaxonomyService.byPath.get('energy')?.path]}
                                newButton={
                                    FlagService.enabledFlags.has(FlagType.EnableElectricity) ? (
                                        <Tooltip
                                            interactive={true}
                                            closeOnInteraction={true}
                                            placement='bottom-end'
                                            hidden={userService.isReadonly()}
                                            tooltipContent={
                                                <>
                                                    <Link
                                                        to={`${ProductService.webRoot}/${ProductService.newId}?type=element&product.taxonomy=${TaxonomyService.electricityPath}`}
                                                        className='dropdown-item small'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            electricityService.showElectricityCreator(true)
                                                        }}
                                                    >
                                                        <Lightning /> {ElectricityService.webTitle()}
                                                    </Link>
                                                    <Link
                                                        to={`${ProductService.webRoot}/${ProductService.newId}?type=element&product.taxonomy=${TaxonomyService.energyPath}`}
                                                        className='dropdown-item small'
                                                    >
                                                        <EnergyIcon isFuel={true} /> {EnergyService.webTitle()}
                                                    </Link>
                                                </>
                                            }
                                            className='btn btn-sm btn-primary text-nowrap'
                                        >
                                            <Plus /> New {ProductService.elementTitle().toLowerCase()}
                                        </Tooltip>
                                    ) : undefined
                                }
                            />
                        </Suspense>
                    }
                />
                <Route
                    path={InventoryService.webRootArchive}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <InventoryPage
                                icon={<Archive size={Utils.mediumIconSize} className='nt--2' />}
                                title='Archive'
                                queryOptions={{
                                    inventoryTypes: [InventoryType.Part, InventoryType.Product],
                                    archived: true,
                                }}
                            />
                        </Suspense>
                    }
                />

                <Route
                    path={`${PartService.webRoot}/:partId`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <PartPage />
                        </Suspense>
                    }
                />

                <Route path='/track/*' element={<Forward from='/track' to={ActivityService.webRootActivities} />} />
                <Route
                    path={`${ActivityService.webRootActivities}/:activityId?`}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <ActivityPage />
                            </Suspense>
                        </ActivityProvider>
                    }
                />
                <Route
                    path={ExchangeService.webRoot}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <ExchangePage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${ActivityService.webRootActivityReports}/*`}
                    element={<Forward from={ActivityService.webRootActivityReports} to={DataImportService.webRoot} />}
                />
                <Route
                    path={DataImportService.webRoot}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <DataImports />
                            </Suspense>
                        </ActivityProvider>
                    }
                />
                <Route
                    path={`${DataImportService.webRootImport}/:dataImportId?/:tab?`}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <DataImportDetail />
                            </Suspense>
                        </ActivityProvider>
                    }
                />

                <Route
                    path={CompanyService.webRootSupplierList}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <SupplierPage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${CompanyService.webRootSupplierList}/*`}
                    element={<Forward from={CompanyService.webRootSupplierList} to={CompanyService.webRootSupplier} />}
                />
                <Route
                    path={`${CompanyService.webRootSupplier}/:supplierId/:tab?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <SupplierPage />
                        </Suspense>
                    }
                />

                <Route
                    path={CompanyService.webRootCustomerList}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <CustomerPage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${CompanyService.webRootCustomer}/*`}
                    element={<Forward from={CompanyService.webRootCustomer} to={CompanyService.webRootCustomer} />}
                />
                <Route
                    path={`${CompanyService.webRootCustomer}/:customerId/:tab?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <CustomerPage />
                        </Suspense>
                    }
                />

                <Route path='/reduce' element={<Forward from='/reduce' to={PlanService.webRoot} />} />
                <Route
                    path={`${PlanService.webRoot}/:tab?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <PlanPage />
                        </Suspense>
                    }
                />
                <Route
                    path={ActivityService.webRootAnalytics}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <AnalyticsPage />
                            </Suspense>
                        </ActivityProvider>
                    }
                />
                <Route
                    path={ActivityService.webRootReport}
                    element={
                        <ActivityProvider>
                            <Suspense fallback={<Spinner />}>
                                <ReportPage />
                            </Suspense>
                        </ActivityProvider>
                    }
                />

                <Route
                    path='/track/requests/*'
                    element={<Forward from='/track/requests' to={DataRequestService.webRootRequests} />}
                />
                <Route
                    path={DataRequestService.webRootRequests}
                    element={<Navigate to={DataRequestService.webRootCollect} />}
                />
                <Route
                    path={DataRequestService.webRootCollect}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <DataRequestPage dataRequestType={DataRequestType.COLLECT} />
                        </Suspense>
                    }
                />
                <Route
                    path={DataRequestService.webRootShare}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <DataRequestPage dataRequestType={DataRequestType.SHARE} />
                        </Suspense>
                    }
                />
                <Route
                    path={`${DataRequestService.webRootRequests}/:dataRequestId`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <DataRequestPage dataRequestType={DataRequestType.COLLECT} />
                        </Suspense>
                    }
                />

                <Route
                    path={`${CalculatorService.webRoot}/:calculatorId?/:calculationId?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <CalculatorPage />
                        </Suspense>
                    }
                />

                <Route
                    path={`${CompanyService.webRootAdmin}/:tab?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <CompanyPage />
                        </Suspense>
                    }
                />
                <Route
                    path={CompanyService.webRootUsers}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <CompanyPage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${CompanyService.webRootAdmin}/users`}
                    element={< Forward from={`${CompanyService.webRootAdmin}/users`} to={CompanyService.webRootUsers} />}
                />
                <Route
                    path={UserService.webRootProfile}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <ProfilePage />
                        </Suspense>
                    }
                />
                <Route
                    path={`${UserService.webRootProfile}/verify/:emailToken?`}
                    element={
                        <Suspense fallback={<Spinner />}>
                            <ProfilePage />
                        </Suspense>
                    }
                />
            </Route>

            <Route
                path='*'
                element={
                    <Suspense fallback={<Spinner />}>
                        <NotFoundPage />
                    </Suspense>
                }
            />
        </Routes>
    )
}

export default App
