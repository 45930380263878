import Utils from '../services/utils'
import UnitService, { UnitSize } from '../services/unit'
import { HTMLAttributes, ReactNode, useContext, useMemo } from 'react'
import { PrettyNumber } from './PrettyNumber'
import ProductService, { Product, ProductVisibility } from '../services/product'
import AuthenticationService from '../services/authentication'
import { UnitHeader } from './UnitHeader'
import { ApplicationContext } from '../context'
import Tooltip from './Tooltip'
import { PlanLabel } from './Navigation'
import { SubscriptionService } from '../services/subscription'
import { VariableServicesContext } from '../services'
import Button from './Input/Button'

const prettyUnits = [
    { threshold: 1_000_000_000_000, unitCode: 'gt' },
    { threshold: 1_000_000_000, unitCode: 'mt' },
    { threshold: 1_000_000, unitCode: 't' },
    { threshold: 0.1, unitCode: 'g' },
]

export type UnitString = 'gt' | 'mt' | 't' | 'kg' | 'g'

export interface CO2eProps {
    co2e: string | number | undefined | null
    updating?: boolean
    spinnerProps?: HTMLAttributes<HTMLSpanElement>
    product?: Product | null
    showPremiumWarning?: boolean
    unitSize?: UnitSize
    precision?: number
    prefix?: ReactNode
    pluralizePrefix?: boolean
    suffix?: ReactNode
    pluralizeSuffix?: boolean
    surround?: string
    onZero?: 'fade' | 'dash'
    shortUnit?: boolean
    showZeroesToPrecision?: boolean
    showZeroesToNumber?: boolean
    className?: string
    numberClassName?: string
    unitClassName?: string
    unitsClassName?: string
    decimalClassName?: string
    functionalUnitSeparator?: string
    functionalUnitClassName?: string
    hidden?: boolean
    showNumber?: boolean
    showUnit?: boolean
    functionalUnit?: string
    zeroValue?: ReactNode
    onClick?: () => void
}

const CO2e = (props: CO2eProps) => {
    const context = useContext(ApplicationContext)
    const { productService, companyService } = useContext(VariableServicesContext)

    const co2eNumber = useMemo(() => {
        return parseFloat((props.co2e || '0').toString())
    }, [props.co2e])

    const precision = useMemo(
        () => context.stores.ui?.decimalPrecision || props.precision || 2,
        [props.precision, context.stores.ui?.decimalPrecision],
    )

    const showZeroesToPrecision = useMemo(() => props.showZeroesToPrecision || false, [props.showZeroesToPrecision])

    const showZeroesToNumber = useMemo(() => {
        if (context.stores.ui?.decimalPrecision) return true
        return props.showZeroesToNumber !== false
    }, [props.showZeroesToNumber, context.stores.ui?.decimalPrecision])

    const inUnit = useMemo(() => {
        if (props.unitSize === 'large') {
            return context.stores.unitLarge || 't'
        }
        return context.stores.unitSmall || 'kg'
    }, [props.unitSize, context.stores.unitLarge, context.stores.unitSmall])

    const prettyUnit = useMemo(() => {
        const foundUnit = prettyUnits.find((item) => {
            if (inUnit && item.unitCode === inUnit) {
                return true
            } else if (item.threshold > 1 && Math.abs(co2eNumber) > item.threshold) {
                return true
            } else {
                return item.threshold < 1 && Math.abs(co2eNumber) < item.threshold
            }
        })
        return UnitService.unitByCode[inUnit || foundUnit?.unitCode || 'kg']
    }, [inUnit, co2eNumber, UnitService.unitByCode])

    const unit = useMemo(
        () => (co2eNumber === 0 && !inUnit ? UnitService.unitByCode['kg'] : prettyUnit),
        [co2eNumber, prettyUnit, inUnit, UnitService.unitByCode],
    )

    const co2eValue = useMemo(() => co2eNumber * (unit?.toBaseUnit || 1), [co2eNumber, unit?.toBaseUnit])

    const prettyNumber = useMemo(() => {
        if (co2eValue || props.onZero) {
            return (
                <PrettyNumber
                    className={props.numberClassName}
                    decimalClassName={props.decimalClassName}
                    onZero={props.onZero}
                    precision={precision}
                    showZeroesToPrecision={showZeroesToPrecision}
                    showZeroesToNumber={showZeroesToNumber}
                    prefix={props.prefix}
                    pluralizePrefix={props.pluralizePrefix}
                    suffix={props.suffix}
                    pluralizeSuffix={props.pluralizeSuffix}
                    surround={props.surround}
                    num={co2eValue}
                />
            )
        }
        return <span className={props.numberClassName || 'font-monospace'}>{props.zeroValue || 0}</span>
    }, [
        co2eValue,
        precision,
        showZeroesToPrecision,
        showZeroesToNumber,
        props.numberClassName,
        props.decimalClassName,
        props.zeroValue,
        props.onZero,
    ])

    // console.log(typeof props.co2e, props.co2e, props.product?.name, props.product?.visibility)
    if (props.product?.co2e === null || props.co2e === null) {
        if (
            props.product?.visibility === ProductVisibility.AUTHENTICATED_USERS &&
            !AuthenticationService.isOnboarded()
        ) {
            return (
                <span hidden={props.hidden} className='btn btn-sm btn-secondary'>
                    Sign up
                </span>
            )
        }
        return (
            <span hidden={props.hidden} className='premium-label'>
                Premium
            </span>
        )
    }

    if (productService.isPremium(props.product || undefined)) {
        return (
            <Tooltip
                trigger='hover'
                placement='top-start'
                interactive={true}
                closeOnInteraction={true}
                disabled={
                    !props.showPremiumWarning ||
                    !props.product?.premium ||
                    !companyService.isMyCompany(props.product?.productOf) ||
                    SubscriptionService.isPremium(context.stores.company?.subscription)
                }
                tooltipClassName='small p-2'
                tooltipStyle={{ width: '300px' }}
                tooltipContent={
                    <span>
                        <p className='mb-1'>
                            {props.product?.name || `This ${ProductService.elementTitle().toLowerCase()}`} is using
                            premium data.
                        </p>
                        <PlanLabel buttonClassName='btn btn-secondary btn-xs' /> to get access to the Premium database,
                        or remove the line items labeled as premium to get the {Utils.co2e} value.
                    </span>
                }
                className={props.className || 'd-inline-block'}
            >
                <PrettyNumber
                    className={props.numberClassName}
                    decimalClassName={props.decimalClassName}
                    onZero={props.onZero || 'dash'}
                    precision={precision}
                    showZeroesToPrecision={showZeroesToPrecision}
                    showZeroesToNumber={showZeroesToNumber}
                    prefix={props.prefix}
                    pluralizePrefix={props.pluralizePrefix}
                    suffix={props.suffix}
                    pluralizeSuffix={props.pluralizeSuffix}
                    surround={props.surround}
                />
            </Tooltip>
        )
    }

    if (!UnitService.units?.length) {
        return null
    }

    return (
        <Button
            element='span'
            className={[
                props.className,
                'position-relative text-nowrap',
                props.updating ? 'opacity-50' : '',
                props.onClick ? 'clickable' : 'cursor-text',
            ].join(' ')}
            hidden={props.hidden}
            onClick={props.onClick}
        >
            {props.showNumber !== false && prettyNumber}
            <span className={props.unitsClassName}>
                {props.showUnit !== false && (
                    <>
                        {!props.unitSize && (
                            <span className={props.unitClassName}>
                                {' '}
                                {unit?.code || 'kg'}
                                {!props.shortUnit && Utils.co2e}
                            </span>
                        )}
                        {props.unitSize && (
                            <UnitHeader extraClassName={props.unitClassName || 'ms-1'} unitSize={props.unitSize} />
                        )}
                    </>
                )}
                {props.functionalUnit !== undefined && (
                    <span className={props.functionalUnitClassName}>
                        {props.functionalUnitSeparator !== undefined ? props.functionalUnitSeparator : '/'}{' '}
                        {props.functionalUnit}
                    </span>
                )}
            </span>
            {props.updating && (
                <span
                    className='position-absolute spinner-border spinner-border-sm ms-1 fs-base text-muted'
                    style={{ marginTop: '2px' }}
                    {...props.spinnerProps}
                />
            )}
        </Button>
    )
}

export default CO2e
