import { AsteriskSimple } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { StandardAttributes } from '../../types'
import { useContext } from 'react'
import { ApplicationContext } from '../../context'

export const PactRequired = (props: StandardAttributes & { required?: boolean }) => {
    const context = useContext(ApplicationContext)
    if (!context.stores.products.highlightPactFields || props.hidden || !props.required) return null
    return (
        <AsteriskSimple
            size={Utils.tinyIconSize}
            className={props.className || `text-danger nt--1 ${props.extraClassName}`}
            style={props.style}
        />
    )
}
