import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { StandardAttributes } from '../types'
import { GeoLocation } from '../services/geoLocation'
import { VariableServicesContext } from '../services'
import Utils from '../services/utils'
import LocationService, { Location } from '../services/location'
import { renderToString } from 'react-dom/server'

// Static maps: https://developers.google.com/maps/documentation/maps-static/start
// Directions: https://developers.google.com/maps/documentation/directions/start

export type MapProps = StandardAttributes & {
    size?: number
    width?: number
    height?: number
    zoom?: number
    locations?: Location[]
    overviewPolyline?: string
    coordinatePath?: string
    paths?: string[]
    origin?: GeoLocation
    destination?: GeoLocation
    clickForDynamic?: boolean
    onPinClick?: (location: Partial<Location>) => void
}

const zoomLevels: { [key: string]: number } = {
    Antarctica: 1,
    ASEAN: 3,
    Canada: 3,
    China: 3,
    EU: 3,
    Europe: 3,
    G7: 1,
    G20: 1,
    Oceania: 3,
    'United States': 3,
    'United Kingdom': 4,
    World: 1,
    ROOFTOP: 12,
}

const GoogleMap = (props: MapProps) => {
    const { analyticsService } = useContext(VariableServicesContext)
    const [useDynamicMaps, setUseDynamicMaps] = useState<boolean>(false)
    const [zoom, setZoom] = useState<number | undefined>(props.zoom)
    const divRef = useRef<any>()

    useEffect(() => {
        return () => setZoom(undefined)
    }, [])

    const dynamicMapsEnabled = useMemo(() => props.clickForDynamic, [props.clickForDynamic])

    const locations = useMemo(
        () =>
            props.locations?.sort((a, b) => {
                if (a.type === 'geo' && b.type !== 'geo') return -1
                if (a.type !== 'geo' && b.type === 'geo') return 1
                return 0
            }),
        [props.locations],
    )

    const width = useMemo(
        () => (props.width || props.size || divRef.current?.offsetWidth || 100) * (props.width ? 1 : 2),
        [props.width, props.size, divRef.current],
    )

    const height = useMemo(
        () => (props.height || props.size || divRef.current?.offsetHeight || 100) * (props.height ? 1 : 2),
        [props.height, props.size, divRef.current],
    )

    const dataReady = useMemo(() => {
        if (!width || !height) return false
        return !!locations?.length || !!props.paths?.length || !!(props.origin && props.destination)
    }, [width, height, locations?.length, props.paths?.length, props.origin, props.destination])

    const staticMapSrc = useMemo(() => {
        if (!dataReady) return
        const params = new URLSearchParams()
        if (locations) {
            locations.forEach((l) => {
                if (l.geoLocation?.latitude && l.geoLocation?.longitude) {
                    const markerColor = l.type === 'geo' ? Utils.secondaryBackgroundColor : Utils.white
                    params.append(
                        'markers',
                        [
                            'size:mid',
                            `color:0x${markerColor.substring(1)}`,
                            l.type !== 'geo' && l.type !== 'other'
                                ? `label:${LocationService.getLocationType(l)?.substring(0, 1)?.toUpperCase()}`
                                : '',
                            `${l.geoLocation?.latitude},${l.geoLocation?.longitude}`,
                        ].join('|'),
                    )
                }
            })
            if (locations.length > 8) params.append('zoom', '2')
            if (locations.length === 1) {
                const zoomMatch =
                    locations[0].geoLocation?.formattedAddress || locations?.[0]?.geoLocation?.locationType || ''
                const hasSpecificZoom = zoomLevels[zoomMatch] || 5
                params.append('zoom', hasSpecificZoom.toString())
                setZoom(hasSpecificZoom)
            }
        } else if (props.overviewPolyline) {
            params.append('path', `enc:${props.overviewPolyline}`)
        } else if (props.coordinatePath) {
            params.append('path', `color:${Utils.primaryBackgroundColor}|weight:5|${props.coordinatePath}`)
        } else if (props.paths) {
            props.paths.forEach((cp) => params.append('path', cp))
            if (props.paths.length > 8) {
                params.append('zoom', '2')
                setZoom(2)
            }
        } else if (props.origin && props.destination) {
            params.append(
                'path',
                `color:${Utils.primaryBackgroundColor}|geodesic:true|weight:5|${props.origin.latitude},${props.origin.longitude}|${props.destination.latitude},${props.destination.longitude}`,
            )
        }
        params.append('size', `${width}x${height}`)
        if (props.zoom) params.append('zoom', props.zoom.toString())
        params.append('key', process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '')
        return `https://maps.googleapis.com/maps/api/staticmap?${params.toString()}`
    }, [width, height, dataReady, locations, props.zoom, props.overviewPolyline, props.origin, props.destination])

    const staticMapImg = useMemo(() => {
        if (!staticMapSrc) return null
        return <img src={staticMapSrc} alt='' className='img-fluid' width='100%' height='100%' />
    }, [staticMapSrc])

    const onClick = useCallback(() => {
        if (!dynamicMapsEnabled) return
        analyticsService.track('Loaded Dynamic Map')
        setUseDynamicMaps(true)
    }, [dynamicMapsEnabled])

    const dynamicOverlay = useMemo(() => {
        if (!dynamicMapsEnabled) return null
        return (
            <div
                role='button'
                tabIndex={0}
                className='fill-parent clickable show-on-hover small d-flex align-items-end justify-content-center'
                onClick={onClick}
                onKeyDown={onClick}
            >
                <div className='w-100 text-center bg-light bg-opacity-75 rounded-2 p-2 m-2'>
                    Click to load interactive map
                </div>
            </div>
        )
    }, [onClick, dynamicMapsEnabled])

    const actualMap = useMemo(() => {
        if (useDynamicMaps)
            return <DynamicMap {...props} width={width} height={height} zoom={zoom} locations={locations} />
        return (
            <>
                {staticMapImg}
                {dynamicOverlay}
            </>
        )
    }, [useDynamicMaps, staticMapImg, dynamicOverlay, props, width, height, zoom, locations])

    return (
        <div
            ref={divRef}
            className={[
                'position-relative hover-parent-direct',
                props.className || 'overflow-hidden rounded-1 bg-light',
                props.extraClassName,
            ].join(' ')}
            style={
                props.size || props.width || props.height
                    ? {
                          height: `${props.height || props.size}px`,
                          width: `${props.width || props.size}px`,
                          maxWidth: '100%',
                          ...props.style,
                      }
                    : props.style
            }
        >
            {actualMap}
        </div>
    )
}

export const DynamicMap = (props: MapProps) => {
    const [mapsCore, setMapsCore] = useState<google.maps.CoreLibrary>()
    const [map, setMap] = useState<google.maps.Map>()
    const [aml, setAml] = useState<google.maps.MarkerLibrary>()
    const [markers, setMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([])
    const mapRef = useRef<any>()

    const pin = useMemo(() => aml && new aml.PinElement({ scale: 0.5 }), [aml])

    useEffect(() => {
        if (!mapRef.current || !props.locations?.length) return
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
            version: 'weekly',
            libraries: ['geocoding'],
        })
        loader.importLibrary('core').then(setMapsCore)
        loader.importLibrary('maps').then(async ({ Map }) => setMap(new Map(mapRef.current, { mapId: 'vmap' })))
        loader.importLibrary('marker').then(setAml)
    }, [mapRef.current])

    // useEffect(() => {
    //     if (!map || !mapsCore) return
    //     map.setOptions({ center: { lat: 0, lng: 0 }, zoom: props.zoom || 8, controlSize: 24 })
    // }, [map, mapsCore, props.zoom])

    useEffect(() => {
        if (!map || !aml || !mapsCore || !pin) return
        const bounds = new mapsCore.LatLngBounds()

        // clear any markers currently on the map
        markers.forEach((m) => (m.map = null))
        setMarkers([])

        props.locations?.forEach((l) => {
            if (l.geoLocation?.latitude && l.geoLocation?.longitude) {
                const infoWindow = new google.maps.InfoWindow({ content: LocationService.getLocationName(l) })
                let pinHtml: HTMLDivElement | undefined
                pinHtml = document.createElement('div')
                pinHtml.className = 'position-relative d-flex align-items-start justify-content-center'
                if (l.type !== 'geo') {
                    pinHtml.innerHTML = renderToString(
                        LocationService.getLocationTypeIcon(l, {
                            className: 'pi position-absolute nt-5',
                            color: Utils.secondaryBackgroundColor,
                            size: Utils.smallIconSize,
                        }),
                    )
                }
                pinHtml.append(
                    new aml.PinElement({
                        scale: l.type === 'geo' ? 0.7 : 1,
                        glyphColor: Utils.white,
                        background: l.type === 'geo' ? Utils.secondaryBackgroundColor : Utils.white,
                        borderColor: l.type === 'geo' ? Utils.white : Utils.secondaryBackgroundColor,
                    }).element,
                )
                const amlElement = new aml.AdvancedMarkerElement({
                    map: map,
                    position: { lat: l.geoLocation?.latitude, lng: l.geoLocation?.longitude },
                    title: LocationService.getLocationName(l),
                    content: pinHtml,
                })
                setMarkers((m) => [...m, amlElement])
                amlElement.content?.addEventListener('mouseover', () => infoWindow.open(map, amlElement))
                amlElement.content?.addEventListener('mouseout', () => infoWindow.close())
                amlElement.addListener('click', () => props.onPinClick?.(l))
                bounds.extend({ lat: l.geoLocation?.latitude, lng: l.geoLocation?.longitude })
            }
        })
        // might want to do pin clustering in the future:
        // https://developers.google.com/maps/documentation/javascript/examples/marker-clustering
        // new MarkerClusterer({ markers, map })
        if (!markers.length) map.fitBounds(bounds)
        map.setOptions({ controlSize: 24 })
        if (props.zoom) setTimeout(() => map.setZoom(props.zoom || 8), 10)
    }, [map, aml, props.locations, props.zoom, mapsCore, pin])

    const style = useMemo(() => {
        if (!props.size && !props.width && !props.height) return props.style
        return {
            height: `${props.height || props.size}px`,
            width: `${props.width || props.size}px`,
            maxWidth: '100%',
            ...props.style,
        }
    }, [props.size, props.width, props.height, props.style])

    if (!props.locations?.length) return null

    return <div ref={mapRef} className={props.className} style={style} />
}

export default GoogleMap
