import { Permission, UiPreferences, UnitPreferences, User, UserActionType, UserWithoutID } from './user-context'
import { Company, UserRole, UserRoleSlug } from './company'
import { LoopsEmailPreferences } from './email'
import { UIOptionActionType } from './ui'
import { KeyValuePair } from '../types'
import VariableService from './service'
import { Devices, Moon, Sun } from '@phosphor-icons/react'
import { ReactNode } from 'react'
import Utils from './utils'
import UnitService, { Unit, UnitType } from './unit'
import FlagService, { FlagType } from './flag'

export interface VerifyTokenResponse {
    type: string
    verified: string
}

export interface UserAndCompanyData {
    user: User
    company?: Company
    companies: Company[]
}

export type ColorMode = 'light' | 'dark' | 'auto'

const DEFAULT_COLOR_MODE: ColorMode = 'light'

export default class UserService extends VariableService {
    private basePath: string = '/user'
    public static webRootProfile: string = '/profile'

    public static ApiUsers: User[] = []

    public colorModes: KeyValuePair<ColorMode>[] = [
        { name: 'Auto', value: 'auto', icon: <Devices /> },
        { name: 'Light', value: 'light', icon: <Sun /> },
        { name: 'Dark', value: 'dark', icon: <Moon /> },
    ]

    public get colorMode(): ColorMode {
        return (localStorage.getItem('colorMode') as ColorMode) || DEFAULT_COLOR_MODE
    }

    public set colorMode(colorMode: ColorMode | null) {
        const currentValue = this.colorMode
        if (this.context.stores.user?.uuid && colorMode) this.setUiPreferences({ colorMode }).then()
        if (colorMode === null || colorMode === DEFAULT_COLOR_MODE) {
            localStorage.removeItem('colorMode')
        } else if (colorMode !== currentValue) {
            localStorage.setItem('colorMode', colorMode)
        }
        this.setColorMode()
    }

    public setColorMode(mode?: ColorMode): void {
        let _cm: ColorMode = mode || this.colorMode
        if (_cm === 'auto') {
            const _prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
            _cm = _prefersDark ? 'dark' : DEFAULT_COLOR_MODE
        }
        this.context.dispatch({ type: UIOptionActionType.SetColorMode, payload: _cm || DEFAULT_COLOR_MODE })
        document.body.setAttribute('data-bs-theme', _cm || DEFAULT_COLOR_MODE)
    }

    public getColorModeIcon(): ReactNode {
        switch (this.context.stores.ui?.colorMode) {
            case 'dark':
                return <Moon size={Utils.verySmallIconSize} />
            default:
                return <Sun size={Utils.verySmallIconSize} />
        }
    }

    public static get numberLocale(): string {
        return localStorage.getItem('numberLocale') || navigator.language
    }

    public static set numberLocale(locale: string | null) {
        const currentValue = this.numberLocale
        if (locale === null) {
            localStorage.removeItem('numberLocale')
        } else if (locale !== currentValue) {
            localStorage.setItem('numberLocale', locale)
        }
    }

    public static get dateLocale(): string {
        return localStorage.getItem('dateLocale') || navigator.language
    }

    public static set dateLocale(locale: string | null) {
        const currentValue = this.dateLocale
        if (locale === null) {
            localStorage.removeItem('dateLocale')
        } else if (locale !== currentValue) {
            localStorage.setItem('dateLocale', locale)
        }
    }

    public static get timeLocale(): string {
        return localStorage.getItem('timeLocale') || navigator.language
    }

    public static set timeLocale(locale: string | null) {
        const currentValue = this.timeLocale
        if (locale === null) {
            localStorage.removeItem('timeLocale')
        } else if (locale !== currentValue) {
            localStorage.setItem('timeLocale', locale)
        }
    }

    public async hasSuperPower(power: string, user?: User | null): Promise<boolean> {
        if (!user) user = this.context.stores.user
        if (!user) return false
        if (user.superPowers?.length) {
            return user.superPowers?.find((sp) => sp.slug === power) !== undefined
        }
        return this.get(user?.uuid).then((u) => {
            return u.superPowers?.find((sp) => sp.slug === power) !== undefined
        })
    }

    public hasAdminRole(user?: User | null): boolean {
        return this.hasRole([UserRoleSlug.OWNER, UserRoleSlug.ADMIN], user)
    }

    public isReadonly(user?: User): boolean {
        return this.hasRole([UserRoleSlug.READONLY], user)
    }

    public hasRole(roleSlugs: UserRoleSlug[], user?: User | null): boolean {
        const _user = user || this.context.stores.user
        if (!_user || !_user?.role?.slug) return false
        return roleSlugs.includes(_user.role.slug)
    }

    public preferredUnit(unitType: UnitType, fallbackUnit?: Unit): Unit {
        let defaultType = UnitService.baseUnitByType.get(unitType)!
        if (unitType === UnitType.DISTANCE) defaultType = UnitService.unitByCode['km']
        if (!unitType) return fallbackUnit || defaultType
        if (!this.context.stores.user) return fallbackUnit || defaultType
        const unitCode = this.context.stores.user?.unitPreferences?.[unitType]
        if (!unitCode) return fallbackUnit || defaultType
        return UnitService.unitByCode?.[unitCode] || fallbackUnit || defaultType
    }

    public async apiPermissions(): Promise<Permission[]> {
        const p = [
            Permission.PACT_READ,
            Permission.ACTIVITY_READ,
            Permission.ACTIVITY_DATA_READ,
            Permission.ORG_READ,
            Permission.SCOPE_READ,
            Permission.TAXONOMY_READ,
        ]
        if (FlagService.enabledFlags.has(FlagType.EnableElementAPI)) {
            p.push(Permission.ELEMENT_READ)
            p.push(Permission.ELEMENT_CREATE)
            p.push(Permission.ELEMENT_UPDATE)
            p.push(Permission.ELEMENT_DELETE)
            p.push(Permission.PRODUCT_READ)
            p.push(Permission.PRODUCT_CREATE)
            p.push(Permission.PRODUCT_UPDATE)
            p.push(Permission.PRODUCT_DELETE)
            p.push(Permission.MODEL_READ)
            p.push(Permission.MODEL_CREATE)
            p.push(Permission.MODEL_UPDATE)
            p.push(Permission.MODEL_DELETE)
            p.push(Permission.SUPPLIER_READ)
            p.push(Permission.SUPPLIER_CREATE)
            p.push(Permission.SUPPLIER_UPDATE)
            p.push(Permission.SUPPLIER_DELETE)
        }
        return p
    }

    public setApiUsers(users: User[]) {
        UserService.ApiUsers = users
        this.context.dispatch({ type: UIOptionActionType.ApiUsersUpdated })
    }

    public updateApiUser(user: User) {
        const index = UserService.ApiUsers.findIndex((u) => u.uuid === user.uuid)
        if (index >= 0) {
            UserService.ApiUsers[index] = user
        } else {
            UserService.ApiUsers.push(user)
        }
        this.context.dispatch({ type: UIOptionActionType.ApiUsersUpdated })
    }

    public async get(userId?: string): Promise<User> {
        let url = this.basePath
        if (userId) {
            url += `/${userId}`
        }
        let u = await this.httpService.get<User>(url)
        this.context.dispatch({ type: UserActionType.Set, payload: u })
        return u
    }

    public async update(user?: Partial<User>): Promise<User> {
        return this.httpService
            .put<User>(`${this.basePath}/${user?.uuid}`, { body: JSON.stringify({ user: user }) })
            .then((u) => {
                this.context.dispatch({ type: UserActionType.Set, payload: u })
                return u
            })
    }

    public async setUiPreferences(uiPreferences: Partial<UiPreferences>): Promise<void> {
        if (!this.context.stores.user) return
        uiPreferences = {
            ...(this.context.stores.user?.uiPreferences as UiPreferences),
            ...(uiPreferences as UiPreferences),
        }
        if (uiPreferences.timeFormat) {
            if (uiPreferences.timeFormat === '12h') {
                UserService.timeLocale = 'en-US'
            } else {
                UserService.timeLocale = 'en-GB'
            }
        }
        this.update({ uuid: this.context.stores.user.uuid, uiPreferences }).catch((e) => console.warn(e))
    }

    public async deleteUser(user: User): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/${user.uuid}`).catch(Utils.errorToast)
    }

    public getFirstDayOfWeek() {
        return this.context.stores.user?.uiPreferences?.startWeekOn === 'sunday' ? 0 : 1
    }

    public async setUnitPreferences(unitPreference: Partial<UnitPreferences>): Promise<void> {
        if (!this.context.stores.user) return
        const unitPreferences: UnitPreferences = {
            ...(this.context.stores.user?.unitPreferences as UnitPreferences),
            ...(unitPreference as UnitPreferences),
        }
        this.update({ uuid: this.context.stores.user.uuid, unitPreferences }).catch((e) => console.warn(e))
    }

    public initNewUser(preferences: Partial<LoopsEmailPreferences>): void {
        this.httpService.put<User>(this.basePath, { body: JSON.stringify({ init: true, preferences }) }).then()
    }

    public getLoopsPreferences(): Promise<LoopsEmailPreferences> {
        return this.httpService.get<LoopsEmailPreferences>(`${this.basePath}/email-preferences?s=l`)
    }

    public setLoopsPreferences(preferences: Partial<LoopsEmailPreferences>): Promise<LoopsEmailPreferences> {
        return this.httpService.put<LoopsEmailPreferences>(`${this.basePath}/email-preferences?s=l`, {
            body: JSON.stringify({ preferences }),
        })
    }

    public getAll(): Promise<User[]> {
        return this.httpService.get<User[]>(`${this.basePath}?a=1`)
    }

    public setPassword(oldPassword: string, password: string): Promise<User> {
        return this.httpService.put<User>(this.basePath, {
            body: JSON.stringify({
                setPassword: true,
                authPassword: this.context.stores.user?.authId !== undefined,
                oldPassword,
                password,
            }),
        })
    }

    public setCompany(user: User, companyName: string): Promise<UserAndCompanyData> {
        return this.httpService.put<UserAndCompanyData>(`${this.basePath}/${user?.uuid}`, {
            body: JSON.stringify({ companyName }),
        })
    }

    public verifyAuth0Email(): Promise<VerifyTokenResponse> {
        return this.httpService.post<VerifyTokenResponse>(this.basePath, {
            body: JSON.stringify({ type: 'auth0Email' }),
        })
    }

    public checkExisting(auth0Id: string): Promise<boolean> {
        return this.httpService.post<boolean>(`${this.basePath}/verify`, {
            body: JSON.stringify({ auth0Id: auth0Id }),
        })
    }

    public setUserRole(user: User, role: UserRole): Promise<User> {
        return this.httpService.put<User>(`${this.basePath}/${user?.uuid}`, {
            body: JSON.stringify({ roleSlug: role.slug }),
        })
    }

    public updateLastLogin(): Promise<void> {
        return this.httpService.post<void>(`${this.basePath}/last-login`)
    }

    public async getApiUsers(): Promise<User[]> {
        return this.httpService.get<User[]>(`${this.basePath}/api`).then((users) => {
            this.setApiUsers(users)
            return users
        })
    }

    public async saveApiUser(apiUser: UserWithoutID, permissions?: Permission[]): Promise<User> {
        return this.httpService
            .post<User>(`${this.basePath}/api`, {
                body: JSON.stringify({ apiUser, permissions }),
            })
            .then((user) => {
                this.updateApiUser(user)
                return user
            })
    }

    public async removeApiPermission(apiUser: User, permission: Permission): Promise<User> {
        return this.httpService.delete<User>(`${this.basePath}/api/${apiUser.uuid}/${permission}`).then((user) => {
            this.updateApiUser(user)
            return user
        })
    }

    public async removeApiUser(apiUser?: User): Promise<void> {
        if (!apiUser) return Promise.reject('No user')
        return this.httpService.delete<User>(`${this.basePath}/api/${apiUser.uuid}`).then(() => {
            UserService.ApiUsers = UserService.ApiUsers.filter((u) => u.uuid !== apiUser.uuid)
            this.context.dispatch({ type: UIOptionActionType.ApiUsersUpdated })
        })
    }
}
