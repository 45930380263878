import { PathfinderAttribute, Product } from '../../services/product'
import InputField from '../Input/InputField'
import Utils from '../../services/utils'
import { Percent, Plus } from '@phosphor-icons/react'
import { Selector } from '../Input/Selector'
import { Checkbox } from '../Input/Checkbox'
import { useContext, useMemo, useState } from 'react'
import { PactRequired } from '../Icons/PactRequired'
import { KeyValuePair } from '../../types'
import { EmissionFactorDataSource } from '../../services/pact'
import Delete from '../Delete'
import { useProduct } from '../../hooks/useProduct'
import { VariableServicesContext } from '../../services'

export const PactField = (props: {
    product: Product
    disabled?: boolean
    onChange: (properties: Partial<Product>) => void
    pactAttr: PathfinderAttribute
}) => {
    const { productService } = useContext(VariableServicesContext)
    const product = useProduct({ product: props.product })

    const _defaultValue = useMemo(() => {
        // @ts-ignore
        let dv = product[`${props.pactAttr.key}`]
        if (props.pactAttr.key === 'pCfIncludingBiogenic' && [undefined, null, ''].includes(dv)) {
            dv = productService.getEmbeddedCO2e(product) || ''
        } else if (props.pactAttr.key === 'pcfPrimaryDataShare' && [undefined, null, ''].includes(dv)) {
            dv = product?.qualitySummary?.specificPercent || 100
        } else if (props.pactAttr.key === 'dqiCoveragePercent' && [undefined, null, ''].includes(dv)) {
            dv = 100
        }
        return dv !== undefined ? dv : []
    }, [props.pactAttr.key, product])

    const [addingDataSource, setAddingDataSource] = useState<boolean>(false)
    const secondarySources = useMemo(() => product?.secondaryEmissionFactorSources || [], [product])

    const secondaryEmissionFactorSources: KeyValuePair<string>[] = useMemo(
        () => [
            { name: 'ecoinvent', value: 'ecoinvent', description: 'Sector: all' },
            { name: 'GaBi', value: 'GaBi', description: 'Sector: all' },
            { name: 'GLEC', value: 'GLEC', description: 'Sector: transportation' },
            { name: 'PEF', value: 'PEF', description: 'Product Environmental Footprints' },
            {
                name: 'Official national emission factor databases',
                value: 'national',
                description: 'E.g., US EPA database',
            },
            { name: 'UNEP', value: 'UNEP', description: 'UNEP Global LCA Data Access Network' },
        ],
        [],
    )

    if (props.pactAttr.type === 'co2e' || props.pactAttr.type === 'number') {
        return (
            <>
                <div className='small' style={{ gridArea: 'auto / key' }}>
                    {props.pactAttr.name}
                    <PactRequired required={props.pactAttr.required} />
                </div>
                <div style={{ gridArea: 'auto / value' }}>
                    <div className='variable-input-group border flex-nowrap bg-white rounded-1'>
                        <InputField
                            id={`pact-input-${props.pactAttr.key}`}
                            disabled={props.disabled}
                            isNumber={true}
                            defaultValue={_defaultValue}
                            followDefaultValue={true}
                            numberCxClassName='w-100'
                            max={props.pactAttr.type === 'number' ? 100 : undefined}
                            numberPrecision={Utils.MAX_DECIMAL_PRECISION}
                            className='variable-form-control text-end w-100'
                            onChangedDebounced={(value) => props.onChange({ [props.pactAttr.key]: value })}
                        />
                        <span className='variable-input-group-text bg-light'>
                            {props.pactAttr.type === 'co2e' && <>kg{Utils.co2e}</>}
                            {props.pactAttr.type === 'number' && <Percent className=' ' />}
                        </span>
                    </div>
                </div>
            </>
        )
    } else if (props.pactAttr.type === 'boolean') {
        return (
            <>
                <div className='small' style={{ gridArea: 'auto / key' }}>
                    {props.pactAttr.name}
                    <PactRequired required={props.pactAttr.required} />
                </div>
                <div className='bg-light' style={{ gridArea: 'auto / value' }}>
                    <Selector
                        disabled={props.disabled}
                        hideTextFilter={true}
                        placement='bottom-start'
                        options={Utils.booleanOptions}
                        option={Utils.booleanOptions.find((bo) => bo.value === _defaultValue)}
                        className='variable-select-arrow'
                        onSelect={(r) => props.onChange({ [props.pactAttr.key]: r.value })}
                    />
                </div>
            </>
        )
    } else if (props.pactAttr.type === 'text') {
        return (
            <div style={{ gridColumn: '1 / span 2' }}>
                <div className='small'>
                    {props.pactAttr.name}
                    <PactRequired required={props.pactAttr.required} />
                </div>
                <InputField
                    disabled={props.disabled}
                    multiLine={true}
                    rows={3}
                    defaultValue={_defaultValue}
                    className='variable-form-control border w-100'
                    onChangedDebounced={(value) => props.onChange({ [props.pactAttr.key]: value })}
                />
            </div>
        )
    } else if (props.pactAttr.type === 'secondaryEmissionFactorSources') {
        const _secondaryEmissionFactorSources = product?.secondaryEmissionFactorSources || []
        return (
            <div style={{ gridColumn: '1 / span 2' }}>
                <div className='small'>{props.pactAttr.name}</div>
                <div className='d-flex flex-column gap-1'>
                    {_secondaryEmissionFactorSources?.map((src, idx) => (
                        <PactSecondaryEmissionFactorSource
                            key={`eds-${src.name}-${src.version}`}
                            source={src}
                            idx={idx}
                            addingDataSource={addingDataSource}
                            onChange={(updatedSource) => {
                                const _newSecondarySources = [...secondarySources]
                                _newSecondarySources.splice(idx, 1, updatedSource)
                                props.onChange({ [props.pactAttr.key]: _newSecondarySources })
                            }}
                            onDelete={() => {
                                const _newSecondarySources = [...secondarySources]
                                _newSecondarySources.splice(idx, 1)
                                props.onChange({ [props.pactAttr.key]: _newSecondarySources })
                            }}
                        />
                    ))}
                    <Selector
                        placeholder='Add source'
                        label={
                            <>
                                <Plus /> source
                            </>
                        }
                        className='btn btn-xs border align-self-start'
                        readonly={true}
                        placement='bottom-start'
                        options={secondaryEmissionFactorSources}
                        onSelect={(newValue) => {
                            setAddingDataSource(true)
                            const _newSecondarySources = [...secondarySources, { name: newValue.value, version: '' }]
                            props.onChange({ [props.pactAttr.key]: _newSecondarySources })
                            setTimeout(() => setAddingDataSource(false), 100)
                        }}
                    />
                </div>
            </div>
        )
    } else if (props.pactAttr.type === 'productOrSectorSpecificRules') {
        let _productOrSectorSpecificRules = product?.productOrSectorSpecificRules || []
        return (
            <div style={{ gridColumn: '1 / span 2' }}>
                <div className='small'>{props.pactAttr.name}</div>
                <div className='d-flex flex-column gap-1'>
                    {_productOrSectorSpecificRules?.map((rule, idx) => (
                        <div
                            key={`pact-rule-${rule.operator}-${rule.ruleNames?.join(',')}`}
                            className='row align-items-center'
                        >
                            <span className='col-5 small'>{rule.operator}</span>
                            <span className='col'>
                                <InputField
                                    placeholder='Rule'
                                    className='variable-form-control border w-100'
                                    defaultValue={rule.ruleNames?.join(', ') || ''}
                                    onChangedDebounced={(value) => {
                                        const _newRules = [..._productOrSectorSpecificRules]
                                        _newRules.splice(idx, 1, { operator: rule.operator, ruleNames: [value] })
                                        props.onChange({ [props.pactAttr.key]: _newRules })
                                    }}
                                />
                            </span>
                            <span className='col-auto'>
                                <Delete
                                    className='align-self-end'
                                    deleteFn={async () => {
                                        const _newRules = [..._productOrSectorSpecificRules]
                                        _newRules.splice(idx, 1)
                                        props.onChange({ [props.pactAttr.key]: _newRules })
                                    }}
                                    iconOnly={true}
                                />
                            </span>
                        </div>
                    ))}
                    <Selector
                        placeholder='Add rule'
                        label={
                            <>
                                <Plus /> rule
                            </>
                        }
                        className='btn btn-xs border align-self-start'
                        readonly={true}
                        placement='bottom-start'
                        options={props.pactAttr.options}
                        onSelect={(newValue) => {
                            const _newRules = [..._productOrSectorSpecificRules, { operator: newValue.value }]
                            props.onChange({ [props.pactAttr.key]: _newRules })
                        }}
                    />
                </div>
            </div>
        )
    } else if (props.pactAttr.type === 'selector') {
        return (
            <>
                <div className='small' style={{ gridArea: 'auto / key' }}>
                    {props.pactAttr.name}
                    <PactRequired required={props.pactAttr.required} />
                </div>
                <div style={{ gridArea: 'auto / value' }}>
                    <Selector
                        placement='bottom-start'
                        className='variable-select-arrow'
                        options={props.pactAttr.options}
                        option={props.pactAttr.options?.find((o) => o.value === _defaultValue)}
                        onSelect={(r) => props.onChange({ [props.pactAttr.key]: r.value })}
                    />
                </div>
            </>
        )
    } else if (props.pactAttr.type === 'list') {
        return (
            <>
                <div className='small' style={{ gridArea: 'auto / key' }}>
                    {props.pactAttr.name}
                    <PactRequired required={props.pactAttr.required} />
                </div>
                <div style={{ gridArea: 'auto / value' }} className='small'>
                    {props.pactAttr.options?.map((opt) => (
                        <label
                            key={`pact-attr-value-${opt.value}`}
                            htmlFor={`pact-attr-value-${opt.value}`}
                            className='d-block'
                        >
                            <Checkbox
                                id={`pact-attr-value-${opt.value}`}
                                checked={_defaultValue?.includes(opt.value)}
                                onChange={(checked) => {
                                    const _properties: Partial<Product> = {
                                        // @ts-ignore
                                        [props.pactAttr.key]: product[`${props.pactAttr.key}`],
                                    }
                                    // @ts-ignore
                                    let _property = _properties[`${props.pactAttr.key}`] || []
                                    if (checked) {
                                        _property.push(opt.value)
                                    } else {
                                        // @ts-ignore
                                        _property = _property.filter((v: any) => v !== opt.value)
                                    }
                                    // @ts-ignore
                                    _properties[`${props.pactAttr.key}`] = _property
                                    props.onChange(_properties)
                                }}
                            />{' '}
                            {opt.name}
                        </label>
                    ))}
                </div>
            </>
        )
    }
}

export const PactSecondaryEmissionFactorSource = (props: {
    source: EmissionFactorDataSource
    idx: number
    addingDataSource: boolean
    onChange: (source: EmissionFactorDataSource) => void
    onDelete: () => void
}) => {
    const isNational = useMemo(() => props.source.name === 'national', [props.source?.name])
    return (
        <>
            <div key={`eds-${props.source.name}-${props.idx}`} className='row align-items-center'>
                <span className='col'>
                    <InputField
                        placeholder='Name'
                        className='variable-form-control border w-100'
                        defaultValue={!isNational ? props.source.name : undefined}
                        focusOnRender={props.addingDataSource && isNational}
                        onChanged={(name) => props.onChange({ name, version: props.source.version })}
                    />
                </span>
                <span className='col'>
                    <InputField
                        placeholder='Version'
                        className='variable-form-control border w-100'
                        defaultValue={props.source.version}
                        focusOnRender={props.addingDataSource && !isNational}
                        onChanged={(version) => props.onChange({ name: props.source.name, version })}
                    />
                </span>
                <span className='col-auto'>
                    <Delete deleteFn={async () => props.onDelete()} iconOnly={true} />
                </span>
            </div>
        </>
    )
}
