import { Link, useNavigate } from 'react-router-dom'
import { ReactNode, useContext, useEffect, useState } from 'react'
import Tooltip from './Tooltip'
import { VariableServicesContext } from '../services'
import { StandardAttributes } from '../types'

export interface Tab {
    isDefault?: boolean
    slug: string
    icon?: ReactNode
    name: ReactNode
    pageTitle?: string
    pageCategory?: string
    content?: ReactNode
    tooltip?: ReactNode
    text?: string
    link?: string
    onClick?: () => void
}

export const Tabs = (
    props: StandardAttributes & {
        tabs: Tab[]
        tab?: Tab
        tabSlug?: string
        tabClassName?: string
        pageTitle?: string
        pageCategory?: string
        useNavigate?: boolean
        basePath?: string
        onChange?: (tab: Tab) => void
    },
) => {
    const navigate = useNavigate()
    const { uiService, analyticsService } = useContext(VariableServicesContext)
    const [currentTab, setCurrentTab] = useState<Tab>(props.tab || props.tabs?.[0])

    useEffect(() => {
        const _tabSlug = props.tabSlug || props.tabs.find((t) => t.isDefault)?.slug || props.tabs[0].slug
        const newTab = props.tabs.find((t) => t.slug === _tabSlug)
        if (newTab) {
            setCurrentTab(newTab)
        }
    }, [props.tabSlug])

    return (
        <div
            className={[
                props.hidden ? 'd-none' : 'd-flex',
                props.className || 'flex-nowrap flex-shrink-0 max-w-100 overflow-auto',
                props.extraClassName,
            ].join(' ')}
        >
            {props.tabs.map((tab) => {
                let _tabName = (
                    <>
                        {tab.icon}
                        <span className={tab.icon ? 'd-none d-sm-inline-block ms-1' : ''}>{tab.name}</span>
                    </>
                )
                if (tab.tooltip) {
                    _tabName = (
                        <Tooltip
                            trigger='hover'
                            tooltipClassName='text-start text-wrap'
                            tooltipStyle={{ maxWidth: '300px' }}
                            tooltipContent={tab.tooltip}
                            onClick={tab.onClick}
                        >
                            {_tabName}
                        </Tooltip>
                    )
                }
                let _tabLink: string
                if (tab.link) {
                    _tabLink = tab.link
                } else {
                    _tabLink = props.basePath || ''
                    if (!tab.isDefault && tab.slug) _tabLink += `/${tab.slug}`
                }
                return (
                    <Link
                        key={`tab-${tab.slug}`}
                        aria-label={`Tab: ${tab.text || tab.slug}`}
                        className={[
                            props.tabClassName || 'py-2 px-3 text-nowrap',
                            currentTab.slug === tab.slug ? 'border-bottom border-3 border-primary' : '',
                        ].join(' ')}
                        to={_tabLink}
                        onClick={(e) => {
                            if (props.disabled) {
                                e.preventDefault()
                                e.stopPropagation()
                                return
                            }
                            if (!tab.link || props.onChange || props.useNavigate) {
                                e.preventDefault()
                                if (props.useNavigate) {
                                    let url = props.basePath || ''
                                    if (!tab.isDefault && tab.slug) url += `/${tab.slug}`
                                    navigate(url)
                                }
                            }
                            const _title = tab?.pageTitle || props.pageTitle
                            if (_title) {
                                uiService.setTitle(_title, tab.pageCategory || props.pageCategory)
                            }
                            tab.onClick?.()
                            props.onChange?.(tab)
                            setCurrentTab(tab)
                            analyticsService.track('Selected Tab', { name: tab.slug })
                        }}
                    >
                        {_tabName}
                    </Link>
                )
            })}
        </div>
    )
}

export const TabContent = (props: { tabs: Tab[]; tabSlug: string; className?: string }) => {
    const _tabSlug = props.tabSlug || props.tabs.find((t) => t.isDefault)?.slug || props.tabs[0].slug
    return (
        <div className={props.className}>
            {props.tabs.map((tab) => (
                <div key={`tab-content-${tab.slug}`} hidden={_tabSlug !== tab.slug}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}
