import { PercentBar, PercentBarProps, PercentBarSegment } from '../PercentBar'
import { InputUseStageSummary } from '../../services/input'
import ProductService, { Product } from '../../services/product'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../../services'

import { Co2eDisplay } from '../../types'
import { UseStageCategoryType } from '../../services/useStage'

export const LifecycleBar = (props: {
    product?: Product
    className?: string
    style?: CSSProperties
    co2eDisplay?: Co2eDisplay
    percentBarProps?: Partial<PercentBarProps>
}) => {
    const { productService } = useContext(VariableServicesContext)
    const [useStageSummaries, setUseStageSummaries] = useState<InputUseStageSummary[]>()
    const [inputSegments, setInputSegments] = useState<PercentBarSegment[]>([])
    const [absTotal, setAbsTotal] = useState<number>(0)

    useEffect(() => {
        if (props.product) setUseStageSummaries(props.product.useStageSummary)
    }, [props.product?.useStageSummary])

    useEffect(() => {
        let _absTotal = 0

        const upstream: PercentBarSegment = { name: 'Upstream', className: 'bg-ghg-upstream', amount: 0 }
        const direct: PercentBarSegment = { name: 'Direct', className: 'bg-ghg-scope2', amount: 0 }
        const useStage: PercentBarSegment = { name: 'Downstream', className: 'bg-ghg-downstream', amount: 0 }
        const endOfLife: PercentBarSegment = { name: 'End-of-life', className: 'bg-ghg-downstream', amount: 0 }
        const unknown: PercentBarSegment = { name: 'Unknown', className: 'bg-dark bg-opacity-10', amount: 0 }

        const _productIsNegative = parseFloat(props?.product?.co2e || '0') < 0

        const _co2eDisplay = props.co2eDisplay || 'Upstream'
        const _showDownstream = _co2eDisplay === 'Total' || _co2eDisplay === 'Downstream'
        const _showUpstream = _co2eDisplay === 'Total' || _co2eDisplay === 'Upstream'

        useStageSummaries?.forEach((ius) => {
            const _amount = ius.amount + ius.byproductCo2e
            if (
                [UseStageCategoryType.UPSTREAM, UseStageCategoryType.DIRECT, UseStageCategoryType.DOWNSTREAM].includes(
                    ius.category as UseStageCategoryType,
                )
            ) {
                if (_productIsNegative) {
                    _absTotal += Math.abs(ius.amount) + Math.abs(ius.byproductCo2e)
                } else {
                    _absTotal += ius.amount + ius.byproductCo2e
                }
            }
            if (ius.category === 'Upstream' && _showUpstream) {
                if (!upstream.amount) upstream.amount = 0
                upstream.amount += _amount
            } else if (ius.category === 'Direct' && _showUpstream) {
                if (!direct.amount) direct.amount = 0
                direct.amount += _amount
            } else if (ius.category === 'Downstream' && _showDownstream) {
                if (!useStage.amount) useStage.amount = 0
                useStage.amount += _amount
            } else if (ius.category === 'End-of-life' && _showDownstream) {
                if (!endOfLife.amount) endOfLife.amount = 0
                endOfLife.amount += _amount
            }
        })

        setAbsTotal(_absTotal)
        const segments = [upstream, direct, useStage, endOfLife, unknown].filter((item) => item.amount)
        setInputSegments([...segments])
    }, [useStageSummaries])

    if (
        productService.isPremium(props.product) ||
        ProductService.isEmissionFactor(props.product) ||
        props.product?.co2e === null
    ) {
        return null
    }

    return (
        <div className={props.className} style={{ maxWidth: '255px', ...props.style }}>
            <PercentBar
                {...props.percentBarProps}
                total={absTotal}
                segments={inputSegments}
                precision={2}
                fillLast={true}
            />
        </div>
    )
}
