import { Product } from '../services/product'
import { PercentBarSegment } from '../components/PercentBar'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Input, InputUseStageSummary } from '../services/input'
import TaxonomyService, { Taxonomy } from '../services/taxonomy'
import Utils from '../services/utils'
import { VariableServicesContext } from '../services'
import { ApplicationContext } from '../context'
import { UseStageCategory } from '../services/useStage'
import { D3Data } from '../types'

export interface ChartData {
    product?: Product
    inputs?: Input[]
    inputCategories?: Map<string, Taxonomy>
    categories?: Taxonomy[]
    productCo2e?: number
    materials?: PercentBarSegment
    transport?: PercentBarSegment
    production?: PercentBarSegment
    distribution?: PercentBarSegment
    use?: PercentBarSegment
    endOfLife?: PercentBarSegment
    unknown?: PercentBarSegment
    inputSegments?: PercentBarSegment[]
    getMainCategory?: (usc?: UseStageCategory | InputUseStageSummary) => PercentBarSegment
}

export const useMainCategories = (props: { product?: Product; chartData?: ChartData }) => {
    const context = useContext(ApplicationContext)
    const { unitService, productService } = useContext(VariableServicesContext)
    const [chartData, setChartData] = useState<ChartData>()
    const [chartDataReady, setChartDataReady] = useState<boolean>(false)
    const [useStageSummaries, setUseStageSummaries] = useState<InputUseStageSummary[]>()

    useEffect(() => setUseStageSummaries(props.product?.useStageSummary), [props.product?.useStageSummary])

    useEffect(() => {
        if (props.chartData) {
            setChartData((state) => ({ ...state, ...props.chartData }))
            setChartDataReady(true)
        }
    }, [props.chartData])

    useEffect(() => {
        const inclusiveCo2e =
            chartData?.inputs
                ?.reduce((acc, cur) => acc.plus(Utils.Decimal(cur.co2e || 0).abs()), Utils.Decimal(0))
                .toNumber() || Utils.Decimal(chartData?.product?.co2e || 0).toNumber()
        setChartData((state) => ({ ...state, productCo2e: inclusiveCo2e }))
    }, [chartData?.product, chartData?.inputs])

    const setupCategories = useCallback(
        async (inputData: Input[]) => {
            const _inputCategories = new Map<string, Taxonomy>()
            const _categories: Map<string, Taxonomy> = new Map()
            await Promise.all(
                inputData.map(async (input) => {
                    let _tx = input.sourceProduct?.taxonomy
                    let _existingCategory = _categories.get(_tx?.uuid || '')
                    const _inputCo2e = unitService.valueSmallUnit?.(input.co2e) || 0
                    if (!_existingCategory) {
                        if (input.transportInstance) _existingCategory = TaxonomyService.byPath.get('transport')
                        if (input.processingType) _existingCategory = TaxonomyService.byPath.get('processing')
                        if (input.useStageType) _existingCategory = TaxonomyService.byPath.get('use')
                    }
                    if (_existingCategory) {
                        _existingCategory.co2e = Utils.Decimal(_existingCategory.co2e || 0)
                            .plus(_inputCo2e)
                            .toString()
                    }
                    if (!_tx && input.part?.uuid) {
                        input.part?.sourceProducts?.forEach((sp) => {
                            if (!_tx) _tx = sp?.taxonomy
                        })
                    }
                    if (_tx) {
                        if (!_existingCategory) _tx.co2e = _inputCo2e.toString()
                        _inputCategories.set(input.uuid!, _tx)
                        if (_tx?.uuid) _categories.set(_tx?.uuid, _tx)
                    }
                    return input
                }),
            )
            setChartData((state) => ({
                ...state,
                inputCategories: _inputCategories,
                categories: Array.from(_categories.values()),
            }))
            setChartDataReady(true)
        },
        [context.stores.ui?.taxonomyReady],
    )

    const getChartData = useCallback(
        async (token?: string) => {
            if (!props.product?.uuid) return
            const _inputs = await productService.getDataViz(props.product.uuid, token)
            setChartData((state) => ({ ...state, product: props.product, inputs: _inputs }))
            setupCategories(_inputs).then()
        },
        [props.product?.uuid],
    )

    const maxPrecision = useMemo(() => {
        const mp = (useStageSummaries || [])?.reduce((acc, cur) => {
            const _precision = cur.amount?.toString().split('.')[1]?.length || 0
            return Math.max(acc, _precision)
        }, 0)
        if (mp > 0) return 2
        return 0
    }, [useStageSummaries])

    const [materials, transport, production, distribution, use, endOfLife, unknown, segments, barChartData] =
        useMemo(() => {
            const defaultClassName = 'bg-dark bg-opacity-10'
            const materials: PercentBarSegment = {
                name: 'Materials',
                key: 'a1',
                className: defaultClassName,
                color: Utils.materialsColor,
                order: 0,
            }
            const transport: PercentBarSegment = {
                name: 'Transport',
                key: 'a2',
                color: Utils.materialsColor,
                className: defaultClassName,
                order: 1,
            }
            const production: PercentBarSegment = {
                name: 'Production',
                key: 'a3',
                color: Utils.productionColor,
                className: defaultClassName,
                order: 2,
            }
            const distribution: PercentBarSegment = {
                name: 'Distribution',
                key: 'a4',
                color: Utils.distributionColor,
                className: defaultClassName,
                order: 3,
            }
            const use: PercentBarSegment = {
                name: 'Use',
                key: 'b',
                color: Utils.useColor,
                className: defaultClassName,
                order: 4,
            }
            const endOfLife: PercentBarSegment = {
                name: 'End-of-life',
                key: 'c',
                color: Utils.endOfLifeColor,
                className: defaultClassName,
                order: 5,
            }
            const reuse: PercentBarSegment = {
                name: 'Reuse',
                key: 'd',
                color: Utils.reuseColor,
                className: defaultClassName,
                order: 6,
            }
            const unknown: PercentBarSegment = {
                name: 'Unknown',
                key: 'u',
                className: 'bg-dark bg-opacity-10',
            }

            useStageSummaries
                ?.filter((ius) => ius?.amount !== null)
                ?.forEach((ius) => {
                    const _amount = Utils.Decimal(unitService.valueSmallUnit?.(ius.amount) || 0).plus(
                        unitService.valueSmallUnit?.(ius.byproductCo2e) || 0,
                    )
                    if (ius.code === 'A1') {
                        materials.amount = Utils.Decimal(materials.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        materials.className = 'bg-lcs-a1'
                    } else if (ius.code === 'A2') {
                        transport.amount = Utils.Decimal(transport.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        transport.className = 'bg-lcs-a2'
                    } else if (ius.code === 'A3') {
                        production.amount = Utils.Decimal(production.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        production.className = 'bg-lcs-a3'
                    } else if (ius.code === 'A4' || ius.code === 'A5') {
                        distribution.amount = Utils.Decimal(distribution.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        distribution.className = 'bg-lcs-a4'
                    } else if (ius.code?.startsWith('B')) {
                        use.amount = Utils.Decimal(use.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        use.className = 'bg-lcs-b'
                    } else if (ius.code?.startsWith('C')) {
                        endOfLife.amount = Utils.Decimal(endOfLife.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        endOfLife.className = 'bg-lcs-c'
                    } else if (ius.code?.startsWith('D')) {
                        reuse.amount = Utils.Decimal(reuse.amount || 0)
                            .plus(_amount)
                            .toNumber()
                        reuse.className = 'bg-lcs-d'
                    } else {
                        unknown.amount = Utils.Decimal(unknown.amount || 0)
                            .plus(_amount)
                            .toNumber()
                    }
                })

            const segments = [
                materials,
                transport,
                production,
                distribution,
                use,
                endOfLife,
                // Reuse is not counted toward the total, so we shouldn't include it in the chart
                // reuse,
                unknown,
            ].filter((item) => !(item.key === 'u' && !item.amount))

            const barChartData: D3Data[] = segments?.map((s) => {
                let _valueLabel = '--'
                if (s.amount !== undefined) {
                    _valueLabel = Utils.toFixedFloat(s.amount, maxPrecision, true, undefined, true)
                }
                return {
                    key: s.key || s.name,
                    name: s.name,
                    value: s.amount,
                    valueLabel: _valueLabel,
                    amount: s.amount,
                    color: s.color,
                    className: s.className,
                }
            })

            return [materials, transport, production, distribution, use, endOfLife, unknown, segments, barChartData]
        }, [useStageSummaries, maxPrecision, context.stores.unitSmall])

    const getMainCategory = useCallback((usc?: UseStageCategory | InputUseStageSummary) => {
        switch (usc?.code) {
            case 'A1':
                return materials
            case 'A2':
                return transport
            case 'A3':
                return production
            case 'A4':
            case 'A5':
                return distribution
            case 'B1':
            case 'B2':
            case 'B3':
            case 'B4':
            case 'B5':
            case 'B6':
            case 'B7':
                return use
            case 'C1':
            case 'C2':
            case 'C3':
            case 'C4':
                return endOfLife
            default:
                return unknown
        }
    }, [])

    return {
        chartData,
        getChartData,
        chartDataReady,
        getMainCategory,
        materials,
        transport,
        production,
        distribution,
        use,
        endOfLife,
        unknown,
        inputSegments: segments,
        barChartData,
        maxPrecision,
    }
}
