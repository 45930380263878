import { ReactNode, useCallback, useMemo } from 'react'
import usePaginate from '../hooks/usePaginate'
import Button from './Input/Button'
import { CaretDown } from '@phosphor-icons/react'
import { OrderDir, QueryOptions } from '../types'

export type SortKey =
    | 'name'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'sku'
    | 'quantity'
    | 'date'
    | 'startDate'
    | 'endDate'
    | 'scope'
    | 'co2e'
    | 'conversion'
    | 'state'
    | 'input'
    | 'company'
    | 'supplier'
    | 'customer'
    | 'created'
    | 'updated'
    | 'org'
    | 'status'
    | 'type'
    | 'uuid'
    | 'usage'
    | 'submittedBy'
    | 'dataQuality'
    | 'account'
    | 'users'
    | 'subscription'
    | 'factor'
    | 'factors'
    | 'action'
    | 'source'
    | 'assignedTo'
    | 'reduction'
    | 'cost'
    | 'total'
    | 'selected'

    // CompanyTableColumnKey
    | 'connectionStatus'
    | 'priority'
    | 'spendValue'
    | 'totalCo2e'
    | 'recipient'
    | 'request'
    | 'activities'
    | 'taxonomy'
    | 'lastLogin'
    | 'role'
    | 'exchange'
    | 'etc'

export interface SortButtonProps {
    sortKey?: SortKey
    isDefault?: boolean
    defaultDir?: OrderDir
    className?: string
    extraClassName?: string
    arrowPlacement?: 'before' | 'after'
    queryOptions?: QueryOptions
    children?: ReactNode
    onClick?: (newParams?: Partial<QueryOptions>) => void
    onChange?: (queryOptions?: QueryOptions) => void
}

export const SortButton = (props: SortButtonProps) => {
    const { setQueryParams, queryOptions } = usePaginate()

    const _qo: QueryOptions = useMemo(
        () => ({ ...queryOptions, ...props.queryOptions }),
        [queryOptions, props.queryOptions],
    )

    const orderBy = useMemo(
        () => _qo?.orderBy || (props.isDefault && props.sortKey),
        [_qo, props.isDefault, props.sortKey],
    )
    const orderDir = useMemo(
        () => _qo?.orderDir || (props.isDefault && props.defaultDir),
        [_qo, props.isDefault, props.defaultDir],
    )
    const newDir: OrderDir = useMemo(() => (orderDir === 'ASC' ? 'DESC' : 'ASC'), [orderDir])

    const caretClassName = useMemo(
        () =>
            [
                props.className || 'position-relative transition-all-300ms',
                props.extraClassName,
                orderBy === props.sortKey ? 'd-block' : 'd-none',
                orderDir === 'ASC' ? 'rotate-180x' : '',
            ].join(' '),
        [props.className, props.extraClassName, orderBy, orderDir, props.sortKey],
    )

    const onClick = useCallback(() => {
        const newParams: QueryOptions = { orderBy: props.sortKey, orderDir: newDir }
        if (props.onClick) {
            return props.onClick(newParams)
        }

        if (props.onChange) {
            return props.onChange(newParams)
        }

        setQueryParams(newParams, false, true)
    }, [props.onClick, props.onChange, props.isDefault, props.sortKey, props.defaultDir, newDir, setQueryParams])

    return (
        <Button onClick={onClick} className='btn-unstyled fw-bold'>
            <span className='d-flex align-items-center gap-1'>
                {props.arrowPlacement === 'before' && <CaretDown className={caretClassName} />}
                <span>{props.children}</span>
                {(!props.arrowPlacement || props.arrowPlacement === 'after') && (
                    <CaretDown className={caretClassName} />
                )}
            </span>
        </Button>
    )
}
