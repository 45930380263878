import { Selector } from '../Input/Selector'
import Utils from '../../services/utils'
import { useNavigate } from 'react-router-dom'
import { Clock } from '@phosphor-icons/react'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import UiService from '../../services/ui'
import { VariableServicesContext } from '../../services'

export const HistoryCrumb = () => {
    const context = useContext(ApplicationContext)
    const { uiService } = useContext(VariableServicesContext)
    const navigate = useNavigate()

    const bc = useMemo(() => UiService.BreadcrumbHistory, [context.stores.ui?.breadcrumbsUpdated])

    return (
        <div className='d-flex align-items-center gap-1'>
            <Selector
                readonly={true}
                hideTextFilter={true}
                disabled={!bc.size}
                placement='bottom-start'
                hotKey='h'
                options={Array.from(bc.values()).reverse()}
                onSelect={(bc) => navigate(uiService.getBreadcrumbLink(bc))}
                renderItemValue={(bc) => <div>{bc?.node?.name}</div>}
                className='bg-transparent outline-0'
                hoverClassName=' '
                label={<Clock color={bc.size ? undefined : Utils.veryFadedColor} />}
            />
        </div>
    )
}
