import { useContext, useEffect, useState } from 'react'
import Utils from '../services/utils'
import { useNavigate } from 'react-router-dom'
import UiService, { NavItem, UIOptionActionType } from '../services/ui'
import ProductService from '../services/product'
import DataRequestService from '../services/dataRequest'
import CompanyService from '../services/company'
import ActivityService from '../services/activity'
import PlanService from '../services/plan'
import { KeyValuePair } from '../types'
import PartService from '../services/part'
import { _navItems } from '../components/Navigation'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import DataImportService from '../services/dataImport'

const tagsToIgnore = ['INPUT', 'TEXTAREA', 'SELECT', 'OPTION']

let previousKey: string | undefined
let modifierKeyDown = false

export const _newThings: NavItem[] = [
    {
        text: ProductService.webTitle(),
        keywords: 'create new',
        url: `${ProductService.webRoot}/${ProductService.newId}`,
        typeString: 'create',
        keyboardShortcut: 'p',
        active: false,
    },
    {
        text: 'Material',
        keywords: 'create new',
        url: `${ProductService.webRoot}/${ProductService.newId}?type=element`,
        typeString: 'create',
        keyboardShortcut: 'm',
        active: false,
    },
    {
        text: `${PartService.webTitle()}`,
        keywords: 'create new',
        url: `${PartService.webRoot}/new`,
        keyboardShortcut: 'b',
        typeString: 'create',
        active: false,
    },
    {
        text: 'Activity',
        keywords: 'create new',
        url: ActivityService.webRootActivityNew,
        keyboardShortcut: 'a',
        typeString: 'create',
        active: false,
    },
    {
        text: 'Supplier',
        keywords: 'create new',
        url: `${CompanyService.webRootSupplier}/new`,
        keyboardShortcut: 's',
        typeString: 'create',
        active: false,
    },
    {
        text: 'Data Request',
        keywords: 'create new',
        url: `${DataRequestService.webRootCollect}?new`,
        keyboardShortcut: 'r',
        typeString: 'create',
        active: false,
    },
    {
        text: 'Decarbonization Action',
        keywords: 'create new',
        url: `${PlanService.webRoot}?new`,
        keyboardShortcut: 'x',
        typeString: 'create',
        active: false,
    },
    {
        text: 'Import data',
        keywords: 'create new import',
        url: DataImportService.webRootCreate,
        keyboardShortcut: 'i',
        typeString: 'create',
        active: false,
    },
]

export const newThingsKeyValue: KeyValuePair<string | undefined>[] = _newThings.map((nt) => {
    return {
        name: (
            <div className='d-flex align-items-center justify-content-between'>
                {nt.text}
                <span className='d-flex align-items-center justify-content-end ms-3'>
                    <span className='keyboard-shortcut'>c</span>
                    <span className='keyboard-shortcut'>{nt.keyboardShortcut}</span>
                </span>
            </div>
        ),
        value: nt.url,
    }
})

export const useKeyboard = () => {
    const context = useContext(ApplicationContext)
    const { uiService } = useContext(VariableServicesContext)
    const navigate = useNavigate()
    const [flatNav, setFlatNav] = useState<NavItem[]>([])
    const [createNewShortcuts, setCreateNewShortcuts] = useState<string[]>([])
    const [keyboardShortcutMatches, setKeyboardShortcutMatches] = useState<string[]>([])

    useEffect(() => {
        if (flatNav.length && keyboardShortcutMatches.length && createNewShortcuts.length) {
            window.addEventListener('keydown', onKeyDown)
            window.addEventListener('keyup', onKeyUp)
        }
        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, [flatNav, keyboardShortcutMatches])

    useEffect(() => {
        const subNav = [..._navItems].filter((i) => i.subNav).flatMap((i) => i.subNav!)
        const fullList = [..._navItems].concat(subNav).sort(Utils.sortByName)
        const shortcuts = fullList.filter((ni) => ni.keyboardShortcut).flatMap((ni) => ni.keyboardShortcut!)
        const createShortcuts = _newThings.filter((ni) => ni.keyboardShortcut).flatMap((ni) => ni.keyboardShortcut!)
        setFlatNav(fullList)
        setKeyboardShortcutMatches(shortcuts)
        setCreateNewShortcuts(createShortcuts)
    }, [])

    const _reset = () => {
        previousKey = undefined
        uiService.setHotKey(undefined)
    }

    const onKeyUp = (e: any) => {
        if (tagsToIgnore.includes(e.target.tagName)) {
            return
        }
        if (
            modifierKeyDown ||
            context.stores.ui?.metaKey ||
            context.stores.ui?.ctrlKey ||
            context.stores.ui?.altKey ||
            context.stores.ui?.shiftKey
        ) {
            modifierKeyDown = Utils.isModifierKey(e)
            context.dispatch({
                type: UIOptionActionType.SetModifierKeys,
                payload: { metaKey: e.metaKey, altKey: e.altKey, ctrlKey: e.ctrlKey, shiftKey: e.shiftKey },
            })
        }
    }

    const onKeyDown = (e: any) => {
        // console.log(e.keyCode, e.shiftKey, e.altKey, e.ctrlKey, e.metaKey)
        if (Utils.isCmdKey(e)) {
            if (e.key === 'k') {
                e.preventDefault()
                e.stopPropagation()
                context.dispatch({
                    type: UIOptionActionType.SetFootprintSearchConfig,
                    payload: { view: 'global', visible: true },
                })
            } else if (e.key === 'j') {
                e.preventDefault()
                e.stopPropagation()
                context.dispatch({
                    type: UIOptionActionType.SetFootprintSearchConfig,
                    payload: { view: 'database', visible: true },
                })
            } else if (e.key === 'i') {
                e.preventDefault()
                e.stopPropagation()
                context.dispatch({
                    type: UIOptionActionType.SetFootprintSearchConfig,
                    payload: { view: 'inventory', visible: true },
                })
            }
        }
        if (tagsToIgnore.includes(e.target.tagName)) {
            return
        }
        if (Utils.isModifierKey(e)) {
            modifierKeyDown = true
            context.dispatch({
                type: UIOptionActionType.SetModifierKeys,
                payload: { metaKey: e.metaKey, altKey: e.altKey, ctrlKey: e.ctrlKey, shiftKey: e.shiftKey },
            })
            if (e.shiftKey && e.altKey && e.ctrlKey && e.metaKey) {
                // 68 = d (which stands for "developer")
                if (e.keyCode === 68) uiService.toggleDebugMode()
                // 80 = p (which stands for "precision")
                if (e.keyCode === 80) uiService.toggleDecimalPrecision()
            }
        } else if (e.key === 'Escape') {
            previousKey = undefined
            context.dispatch({ type: UIOptionActionType.EscapeKey })
            return
        } else if (previousKey === 'g') {
            if (keyboardShortcutMatches?.includes(e.key)) {
                const ni = flatNav.find((ni) => ni.keyboardShortcut === e.key)
                const url = ni?.target || ni?.url
                if (url) {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(url)
                    _reset()
                }
            } else if (e.key === 'd') {
                context.dispatch({
                    type: UIOptionActionType.SetFootprintSearchConfig,
                    payload: { view: 'database', visible: true },
                })
            }
        } else if (previousKey === 'h' && e.key === 'h') {
            uiService.setHotKey(e.key)
        } else if (previousKey === 'c') {
            if (e.key === 'c') {
                uiService.setHotKey(e.key)
            }
            if (createNewShortcuts?.includes(e.key)) {
                const ni = _newThings.find((ni) => ni.keyboardShortcut === e.key)
                const url = ni?.target || ni?.url
                if (url) {
                    e.preventDefault()
                    e.stopPropagation()
                    context.dispatch({ type: UIOptionActionType.EscapeKey })
                    navigate(url)
                    _reset()
                }
            }
        } else if (!Utils.isModifierKey(e) && UiService?.hotKeys?.has(e.key)) {
            uiService.setHotKey(e.key)
        }
        previousKey = e.key
    }
}
