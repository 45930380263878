import { Tag } from '../services/tag'
import ProductService, { Product } from '../services/product'
import { useMemo } from 'react'
import { useProduct } from '../hooks/useProduct'
import { StandardAttributes } from '../types'

export const TagList = (props: {
    attrs?: StandardAttributes
    tags?: Tag[]
    product?: Product
    scrolling?: 'horizontal' | 'vertical'
}) => {
    const product = useProduct({ product: props.product })
    const tags = useMemo(() => {
        if (product) return ProductService.getAkaTags(product)
        return props.tags
    }, [props.tags, product])
    return (
        <div
            hidden={!tags?.length || props.attrs?.hidden}
            className={[props.attrs?.className || 'overflow-auto max-w-100', props.attrs?.extraClassName].join(' ')}
            style={{ maxHeight: '5rem', ...props.attrs?.style }}
        >
            <div className={['d-flex gap-1', props.scrolling === 'horizontal' ? '' : 'flex-wrap'].join(' ')}>
                {tags?.map((tag) => (
                    <span key={`srm-tag-${tag.slug}`} className='tag tag-sm m-0'>
                        {tag.name}
                    </span>
                ))}
            </div>
        </div>
    )
}
