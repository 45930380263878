import Button from '../Input/Button'
import { Plug, Plus, Sparkle, Wrench } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import Tooltip from '../Tooltip'
import { ReactNode, useContext, useMemo, useState } from 'react'
import { VariableServicesContext } from '../../services'
import { useInputType } from '../../hooks/useInputType'
import { Product } from '../../services/product'
import InputService, { Input, InputType } from '../../services/input'
import { ApplicationContext } from '../../context'
import { UseStageCategoryType } from '../../services/useStage'
import { TransportIcons } from '../Icons/TransportIcons'

export const InputAddButtons = (props: {
    product: Product
    inputIds?: Set<string>
    inputTypes?: InputType[]
    useStageType?: UseStageCategoryType
    addText?: string
    className?: string
    btnClassName?: string
    onChange: (inputs?: Input | Input[]) => void
}) => {
    const context = useContext(ApplicationContext)
    const { analyticsService, productService, partService, inputService, aiService } =
        useContext(VariableServicesContext)
    const [fetchingAi, setFetchingAi] = useState<boolean>(false)
    const { createTransportInstance, createProcessingType, createUseStageType } = useInputType({
        instanceId: props.product?.uuid || '',
    })
    const [addingInput, setAddingInput] = useState<boolean>(false)
    const [addingProcessing, setAddingProcessing] = useState<boolean>(false)
    const [addingTransport, setAddingTransport] = useState<boolean>(false)
    const [addingUseStage, setAddingUseStage] = useState<boolean>(false)
    const btnClassName = props.btnClassName || 'btn btn-sm btn-light'

    const addInput = useMemo(
        () => (
            <Button
                key='add-input'
                className={btnClassName}
                saving={addingInput}
                disabled={!props.product?.uuid}
                onClick={async () => {
                    setAddingInput(true)
                    let _usc
                    if (props.useStageType === 'Downstream') {
                        _usc = InputService.UseStageByCode.get('B2')
                    }
                    let _newInput = await productService.createInput(props.product?.uuid!)
                    _newInput = await inputService.updateInput({ uuid: _newInput.uuid, useStageCategory: _usc })
                    setAddingInput(false)
                    props.onChange(_newInput)
                    analyticsService.track('Added Input')
                }}
            >
                <Plus size={Utils.verySmallIconSize} className='nt--2' /> {props.addText || 'Input'}
            </Button>
        ),
        [
            btnClassName,
            addingInput,
            props.product?.uuid,
            props.useStageType,
            props.onChange,
            props.addText,
            context.stores.ui?.useStagesReady,
        ],
    )

    const addProcessing = useMemo(
        () => (
            <Button
                key='add-processing'
                className={btnClassName}
                saving={addingProcessing}
                disabled={!props.product?.uuid}
                onClick={async () => {
                    setAddingProcessing(true)
                    await createProcessingType(props.product).then(props.onChange)
                    setAddingProcessing(false)
                }}
            >
                <Wrench size={Utils.verySmallIconSize} /> Production
            </Button>
        ),
        [btnClassName, addingProcessing, createProcessingType, props.product?.uuid, props.onChange],
    )

    const addTransport = useMemo(
        () => (
            <Button
                key='add-transport'
                className={btnClassName}
                saving={addingTransport}
                disabled={!props.product?.uuid}
                onClick={async () => {
                    setAddingTransport(true)
                    const inputWithTransport = await createTransportInstance(props.product)
                    const tdInput = await inputService.updateInput({
                        uuid: inputWithTransport.uuid,
                        useStageCategory: InputService.UseStageByCode.get('A4'),
                    })
                    props.onChange(tdInput)
                    setAddingTransport(false)
                }}
            >
                <TransportIcons className='nt--1' /> Distribution &amp; Storage
            </Button>
        ),
        [
            btnClassName,
            addingTransport,
            createTransportInstance,
            props.product?.uuid,
            props.onChange,
            context.stores.ui?.useStagesReady,
        ],
    )

    const addUseStage = useMemo(
        () => (
            <Button
                key='add-use-stage'
                className={btnClassName}
                saving={addingUseStage}
                disabled={!props.product?.uuid}
                onClick={async () => {
                    setAddingUseStage(true)
                    await createUseStageType(props.product).then(props.onChange)
                    setAddingUseStage(false)
                }}
            >
                <Plug size={Utils.verySmallIconSize} /> Use Stage
            </Button>
        ),
        [btnClassName, addingUseStage, createUseStageType, props.product?.uuid, props.onChange],
    )

    const addAi = useMemo(
        () => (
            <Tooltip
                key='add-ai'
                trigger='hover'
                disabled={fetchingAi}
                tooltipClassName='small'
                tooltipContent='Have our AI generate a Bill of Materials for this product'
                hidden={!props.inputIds || props.inputIds.size > 0}
                className='btn btn-xs btn-ai'
                onClick={() => {
                    if (!props.inputIds?.size && props.product?.name) {
                        setFetchingAi(true)
                        aiService
                            .getBom(props.product)
                            .then(async () => {
                                await partService.fetchInventory()
                                if (!props.product?.uuid) return
                                productService.getInputs(props.product?.uuid).then(props.onChange)
                            })
                            .catch((e) => {
                                Utils.errorToast(
                                    e,
                                    'Our AI is having some trouble right now. Please try again later.',
                                    { position: 'bottom-center' },
                                )
                            })
                            .finally(() => setFetchingAi(false))
                        analyticsService.track('Clicked AI BOM')
                    }
                }}
            >
                <Sparkle color={Utils.warningColor} /> AI BOM
                {fetchingAi && <span className='ms-1 mt--1 nt-2 spinner-border spinner-border-sm' />}
            </Tooltip>
        ),
        [fetchingAi, props.inputIds, props.product, props.onChange],
    )

    const buttons = useMemo(() => {
        const _buttons: ReactNode[] = []
        if (!props.inputTypes || props.inputTypes.includes('input')) {
            _buttons.push(addInput)
        }
        if (!props.inputTypes || props.inputTypes.includes('processing')) {
            _buttons.push(addProcessing)
        }
        if (!props.inputTypes || props.inputTypes.includes('transport')) {
            _buttons.push(addTransport)
        }
        if (!props.inputTypes || props.inputTypes.includes('use-stage')) {
            _buttons.push(addUseStage)
        }

        if (!props.inputTypes || props.inputTypes.includes('ai')) {
            _buttons.push(addAi)
        }
        return _buttons
    }, [props.inputTypes, addInput, addProcessing, addTransport, addUseStage, addAi])

    return <div className={props.className || 'd-flex flex-wrap align-items-center gap-2'}>{buttons}</div>
}
